import { useEffect, useReducer } from "react";
import {
  collection,
  collectionGroup,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";

import { httpHelper, firebaseHelper, authHelper } from "../_helpers";

/**
 * Hook para acompanhar as modificações de coleções de chat no firestore.
 * @returns
 */
export const useChatsMensagensNaoLidas = () => {
  /**
   * Reducer para as mensagens não lidas de um chat.
   * @param {*} state
   * @param {*} action
   * @returns
   */
  const msgNaoLidasReducer = (state, action) => {
    if (action.type === "added") {
      state.chats.set(action.chat.uuid, action.chat.qtdMsgNaoLidas);
    } else if (action.type === "modified") {
      state.chats.set(action.chat.uuid, action.chat.qtdMsgNaoLidas);
    } else if (action.type === "removed") {
      state.chats.delete(action.chat.uuid);
    }

    state = {
      ...state,
      qtdMsgNaoLidas: Array.from(state.chats.values()).reduce(
        (a, b) => a + b,
        0
      ),
    };

    return { ...state };
  };

  const [stateSetor, dispatchMsgNaoLidasSetor] = useReducer(
    msgNaoLidasReducer,
    {
      qtdMsgNaoLidas: 0,
      chats: new Map(),
    }
  );
  const [stateComunicados, dispatchMsgNaoLidasComunicados] = useReducer(
    msgNaoLidasReducer,
    {
      qtdMsgNaoLidas: 0,
      chats: new Map(),
    }
  );
  const [stateProfessores, dispatchMsgNaoLidasProfessores] = useReducer(
    msgNaoLidasReducer,
    {
      qtdMsgNaoLidas: 0,
      chats: new Map(),
    }
  );

  const atualizarMensagensNaoLidasPrivado = async (
    mapChatsNaoLidos,
    dispatch
  ) => {
    const response = await httpHelper.get("/seguro/chatrooms/professores/");
    if (response) {
      let chatrooms = response.data;
      if (chatrooms) {
        // Para cada chatroom do usuário logado verifica se tem alguma mensagem não lida.
        // Então lança a mensagem não lida para ser contabilizada.
        chatrooms.forEach((chatroom) => {
          if (mapChatsNaoLidos.has(chatroom.uuid)) {
            mapChatsNaoLidos.get(chatroom.uuid).forEach((value) => {
              dispatch(value);
            });
          }
        });
      }
    }
  };

  // Construtor.
  useEffect(() => {
    // Chats de Setor
    const uuidSetorUsuarioLogado =
      authHelper.getClaimsUsuarioLogado().setor.uuid;
    const chatSetorRef = collectionGroup(
      firebaseHelper.firestore,
      "chat-individual"
    );
    const docs_chat_setor = query(
      chatSetorRef,
      where("setor.uuid", "==", uuidSetorUsuarioLogado),
      orderBy("data_ultima_mensagem", "desc")
    );

    // O retorno da query é uma lista de documentos que representam as conversas de cada usuário com o setor.
    // Então cada documento possui informações sobre a conversa da um determinado responsável com o setor do
    // funcionário logado.
    const unsubscribeChatsSetor = onSnapshot(docs_chat_setor, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const data = change.doc.data();
        const qtdNaoLidas = data.qtd_nao_lida_setor;
        const uuidResponsavel = change.doc.ref.parent.parent.id;
        // Cada responsável tem suas mensagens não lidas para o setor.
        dispatchMsgNaoLidasSetor({
          type: change.type,
          chat: {
            uuid: uuidResponsavel,
            qtdMsgNaoLidas: qtdNaoLidas,
          },
        });
      });
    });

    // Chats de comunicados
    // No caso dos comunicados, cada sala de chat usa o UUID do comunicado criado no backend como chave.
    const chatComunicadoRef = collection(
      firebaseHelper.firestore,
      "comunicados"
    );

    const queryComunicadosRef = query(chatComunicadoRef, orderBy("chats_nao_lidos_setor"));

    const unsubscribeChatsComunicados = onSnapshot(
      queryComunicadosRef,
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const uuidComunicado = change.doc.id;
          const data = change.doc.data();
          const mapChatsNaoLidos = new Map(
            Object.entries(data?.chats_nao_lidos_setor || {})
          );

          dispatchMsgNaoLidasComunicados({
            type: change.type,
            chat: {
              uuid: uuidComunicado,
              qtdMsgNaoLidas: Array.from(mapChatsNaoLidos.values()).reduce(
                (a, b) => a + b,
                0
              ),
            },
          });
        });
      }
    );

    // Chats de professores
    // No caso dos chats de professores, essa query retorna apenas as mensagens que possuem o campo recebida como false.
    // e que tenham sido envidados por um responsável
    // Por isso esse GroupCollection retornam apenas as mensagens de chatrooms, que é a coleção pai que possue as mensagens
    // dos chats de professores.
    const chatsProfessoresRef = collectionGroup(
      firebaseHelper.firestore,
      "mensagens"
    );

    const qry_chat_professores = query(
      chatsProfessoresRef,
      where("recebida", "==", false),
      orderBy("uuid-responsavel")
    );

    const unsubscribeChatsProfessores = onSnapshot(
      qry_chat_professores,
      (snapshot) => {
        const mapChatsNaoLidos = new Map();

        snapshot.docChanges().forEach((change) => {
          const uuidMensagem = change.doc.ref.id;
          const chatroomId = change.doc.ref.parent.parent.id;

          const value = {
            type: change.type,
            chat: {
              uuid: uuidMensagem,
              qtdMsgNaoLidas: 1,
            },
          };

          // Cria um map das mensagens não lidas por chatroom.
          // Então verifica se o usuário logado possui alguma das mensagens não lidas.
          if (mapChatsNaoLidos.has(chatroomId)) {
            const chatroomsMensagens = mapChatsNaoLidos.get(chatroomId);
            chatroomsMensagens.push(value);

            mapChatsNaoLidos.set(chatroomId, chatroomsMensagens);
          } else {
            mapChatsNaoLidos.set(chatroomId, new Array(value));
          }
        });

        atualizarMensagensNaoLidasPrivado(
          mapChatsNaoLidos,
          dispatchMsgNaoLidasProfessores
        );
      }
    );

    return () => {
      // Faz o unsubscribe dos dados gerais do chat.
      unsubscribeChatsSetor();
      unsubscribeChatsComunicados();
      unsubscribeChatsProfessores();
    };
  }, []);

  return {
    setor: {
      qtdMsgNaoLidas: stateSetor.qtdMsgNaoLidas,
    },
    comunicados: {
      qtdMsgNaoLidas: stateComunicados.qtdMsgNaoLidas,
    },
    professores: {
      qtdMsgNaoLidas: stateProfessores.qtdMsgNaoLidas,
    },
  };
};
