import { createAsyncAction } from 'redux-promise-middleware-actions';
import { parametrosService } from '../_services/parametros.service';

export const obterParametros = createAsyncAction('PARAMETROS_OBTER', async () => {
  return await parametrosService.obterParametros();
});

export const alterarParametros = createAsyncAction('PARAMETROS_ALTERAR', async (dados) => {
  return await parametrosService.alterarParametros(dados);
});

global._obterParametros = obterParametros;
global._alterarParametros = alterarParametros;
