import { httpHelper } from '../_helpers';

export const rotinaService = {
  obterTodasRotinasAlunos,
  gravarRotinaAluno,
  obterRotinaAlunoPorUuid,
  alterarRotinaAluno,

  obterTodasRotinasTurmas,
  gravarRotinaTurma,
  obterRotinaTurmaPorUuid,
  alterarRotinaTurma,
  obterRotinasTurmasPaginada,
  obterRotinasAlunosPorUuidData
};

async function obterTodasRotinasAlunos(status = true) {
  return httpHelper.get('/seguro/rotinas_alunos/todos' /*, { params: { status } }*/);
}

async function gravarRotinaAluno(dados) {
  return httpHelper.post('/seguro/rotinas_alunos/', { data: dados });
}

async function obterRotinaAlunoPorUuid(uuid, data) {
  return httpHelper.get(`/seguro/rotinas_alunos/pesquisa/aluno/${uuid}`, {
    params: { data }
  });
}

async function alterarRotinaAluno(dados) {
  return httpHelper.put(`/seguro/rotinas_alunos/`, { data: dados });
}

async function obterTodasRotinasTurmas(status = true) {
  return httpHelper.get('/seguro/rotinas_turmas/', { params: { status } });
}

async function gravarRotinaTurma(dados) {
  return httpHelper.post('/seguro/rotinas_turmas/', { data: dados });
}

async function obterRotinaTurmaPorUuid(uuid, data) {
  return httpHelper.get(`/seguro/rotinas_turmas/pesquisa/turma/${uuid}`, {
    params: { data }
  });
}

async function alterarRotinaTurma(dados) {
  return httpHelper.put(`/seguro/rotinas_turmas/`, { data: dados });
}

async function obterRotinasTurmasPaginada(pagina, tamanhoPagina, ano = undefined) {
  return httpHelper.get('/seguro/rotinas_turmas/retaguarda/paginado/', {
    params: { page: pagina, page_size: tamanhoPagina, ano }
  });
}

async function obterRotinasAlunosPorUuidData(uuid_turma, data_rotina) {
  return httpHelper.get(
    `/seguro/rotinas_alunos/retaguarda/turma/${uuid_turma}/data_rotina/${data_rotina}`
  );
}
