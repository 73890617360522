import React, { useState, useEffect } from "react";

import { Link as RouterLink } from "react-router-dom";
import { withSnackbar } from "notistack";
import queryString from "query-string";

import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import withStyles from "@material-ui/core/styles/withStyles";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";

import { loginStyles } from "../../components/styles/LoginStyles";
import AdornedButton from "../../components/AdornedButton";

import { validaEmail } from "../../_validators/email.validator";

import { authService } from "../../_services";

import Rotas from "../../_constants/rotas";
import mapMensagensFirebase from "../../_constants/firebase.mensagens";

/**
 * Tela de login da aplicação.
 * @param {*} props
 * @returns
 */
const Login = (props) => {
  const [info, setInfo] = useState("");
  const { classes } = props;
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (
      queryString.parse(props.location.search, { ignoreQueryPrefix: true })
        .mode === "resetPassword"
    ) {
      props.history.push(
        Rotas.autenticacao.definir_nova_senha + "/" + props.location.search
      );
    } else if (
      queryString.parse(props.location.search, { ignoreQueryPrefix: true })
        .mode === "verifyEmail"
    ) {
      props.history.push(
        Rotas.autenticacao.verificacao_conta + "/" + props.location.search
      );
    }

    if (
      process.env.REACT_APP_ENV !== undefined ||
      process.env.REACT_APP_ENV !== ""
    ) {
      if (
        !process.env.REACT_APP_API_SERVER.toLowerCase().includes(
          "api.".toLowerCase()
        )
      ) {
        setInfo(
          <div>
            Ambiente: {process.env.REACT_APP_ENV}
            <br />
            Firebase ID: {process.env.REACT_APP_FIREBASE_PROJECT_ID}
            <br />
            Host da API: {process.env.REACT_APP_API_SERVER}
            <br />
            Versão: {process.env.REACT_APP_VERSAO_WEB_APP}
            <br />
            Data: {process.env.REACT_APP_DATA_BUILD}
          </div>
        );
      } else {
        setInfo(
          <div>
            Versão: {process.env.REACT_APP_VERSAO_WEB_APP}
            <br />
            Data: {process.env.REACT_APP_DATA_BUILD}
          </div>
        );
      }
    }
  }, []);

  const handleChangeEmail = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  const handleChangeSenha = (e) => {
    const { value } = e.target;
    setSenha(value);
  };

  /**
   * Envia o form para validação dos dados.
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);

    if (!(email && senha)) {
      return;
    }

    authService
      .login(email, senha)
      .then(() => {
        props.history.push(Rotas.home);
      })
      .catch((error) => {
        // Erro do Firebase
        if (error?.code !== undefined) {
          props.enqueueSnackbar(mapMensagensFirebase.get(error?.code), {
            variant: "error",
          });
        } else {
          // Erro da API
          props.enqueueSnackbar(error?.response?.data?.message, {
            variant: "error",
          });
        }
      })
      .finally(() => setSubmitted(false));
  };

  return (
    <main className={classes.main}>
      <CssBaseline />
      <form onSubmit={handleSubmit}>
        <Paper>
          <Box
            boxSizing="content-box"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            width={340}
            height={340}
            p={6}
          >
            <img
              className={classes.logoImage}
              alt="logo"
              src={require("../../assets/imgs/ludica_logo_horizontalxhdpi_2x.png")}
            />

            <Box display="flex" flexDirection="column">
              <div style={{ height: "32px" }} />
              <TextField
                name="email"
                label="E-mail"
                fullWidth
                value={email}
                onChange={handleChangeEmail}
                autoFocus
                variant="outlined"
              />
              <div style={{ height: "16px" }} />
              <TextField
                name="senha"
                label="Senha"
                type="password"
                value={senha}
                variant="outlined"
                onChange={handleChangeSenha}
              />
              <div style={{ paddingTop: "8px" }} />
              <Link
                style={{ alignSelf: "flex-end" }}
                name="link_esqueci_senha"
                component={RouterLink}
                to={Rotas.autenticacao.esqueci_senha}
              >
                Esqueceu a senha?
              </Link>
            </Box>

            <AdornedButton
              loading={submitted}
              type="submit"
              variant="contained"
              color="primary"
              name="btnEntrar"
              className={classes.submit}
              disabled={!validaEmail(email) || senha.length < 6 || submitted}
            >
              Entrar
            </AdornedButton>
          </Box>
        </Paper>
      </form>
      <div
        style={{
          position: "absolute",
          right: 0,
          bottom: 0,
          color: "#9c9c9c",
          fontSize: "12px",
          padding: "16px 24px 24px",
        }}
      >
        {info}
      </div>
    </main>
  );
};

export default withStyles(loginStyles)(withSnackbar(Login));
