import { authHelper } from '.';

export const convertRotinaTurmaEmTO = (rotina) => {
  let to = {
    disciplinas: (rotina.atividades || []).map((atividade) => {
      atividade.disciplina.observacao = atividade.descricao;

      return atividade.disciplina;
    })
  };
  return { ...to };
};

export const convertRotinaEmTO = (rotinaTurma, rotinaAluno, initialValues) => {
  const cardapiosFiltrados = (initialValues.cardapios || []).filter(
    (cardapio) => cardapio.value.alergico === initialValues.aluno.value.alergico
  );

  let to = {
    turma: initialValues.turma,
    data: initialValues.data,
    disciplinas: rotinaTurma
      ? getDisciplinas(initialValues.disciplinas, rotinaTurma.atividades)
      : initialValues.disciplinas,
    aluno: initialValues.aluno,
    presente: rotinaAluno ? rotinaAluno.presente : initialValues.presente,
    cuidados_template: {
      tipo: '',
      inicio: null,
      fim: null,
      duracao: null,
      observacao: ''
    },
    cuidados: [
      {
        tipo: 'inicio',
        inicio: getData(initialValues.data, 0, 0, 0),
        duracao: null,
        fim: null,
        observacao: ''
      },
      {
        tipo: 'fim',
        inicio: getData(initialValues.data, 23, 59, 59),
        duracao: null,
        fim: null,
        observacao: ''
      }
    ].concat(
      rotinaAluno
        ? (rotinaAluno.cuidados || []).map((cuidado) => {
            return {
              id: cuidado.id,
              tipo: cuidado.cuidado,
              inicio: new Date(cuidado.inicio_cuidado),
              duracao: getDuracao(cuidado.inicio_cuidado, cuidado.fim_cuidado),
              fim: new Date(cuidado.fim_cuidado),
              observacao: cuidado.observacao.toString()
            };
          })
        : []
    ),
    humor: rotinaAluno ? rotinaAluno.humor : initialValues.humor,
    cardapios: rotinaAluno
      ? getCardapios(cardapiosFiltrados, rotinaAluno.cardapios)
      : cardapiosFiltrados,
    observacao: rotinaAluno ? rotinaAluno.observacao : initialValues.observacao
  };

  return { ...to };
};

export const convertTOEmRotinaTurma = (to) => {
  const rotina = {
    data_rotina: to.data,
    funcionario: { uuid: authHelper.getClaimsUsuarioLogado().uuid },
    turma: { uuid: to.turma.value.uuid, nome: to.turma.value.nome },
    atividades: (to.disciplinas || [])
      .filter((disciplina) => !!disciplina.observacao && disciplina.observacao.trim() !== '')
      .map((disciplina) => ({
        id: disciplina.id_atividade || null,
        disciplina: { uuid: disciplina.uuid },
        descricao: disciplina.observacao
      }))
  };

  return { ...rotina };
};

export const convertTOEmRotinaAluno = (to) => {
  const rotina = {
    data_rotina: to.data,
    turma: { uuid: to.turma.value.uuid, nome: to.turma.value.nome },
    aluno: to.aluno.value,
    funcionario: { uuid: authHelper.getClaimsUsuarioLogado().uuid },
    presente: to.presente,
    observacao: to.observacao,
    humor: to.humor,
    cuidados: (to.cuidados || [])
      .filter((cuidado) => cuidado.tipo !== 'inicio' && cuidado.tipo !== 'fim')
      .map((cuidado) => {
        return {
          id: cuidado.id || null,
          cuidado: cuidado.tipo,
          inicio_cuidado: cuidado.inicio,
          fim_cuidado: getDataFimCuidado(cuidado.inicio, cuidado.duracao),
          observacao: cuidado.observacao.toString()
        };
      }),
    cardapios: (to.cardapios || [])
      .filter(
        (cardapio) =>
          cardapio.value.alergico === to.aluno.value.alergico &&
          cardapio.value.quantidade !== null &&
          cardapio.value.quantidade >= 0
      )
      .map((cardapio) => {
        return {
          id: cardapio.value.id || null,
          cardapio: { uuid: cardapio.value.uuid },
          qt_comeu: cardapio.value.quantidade,
          observacao_nao_planejado:
            cardapio.value.nao_planejados === null || cardapio.value.nao_planejados === ''
              ? null
              : cardapio.value.nao_planejados,
          ingredientes: (cardapio.value.ingredientes || []).map((ingrediente) => {
            return {
              id: ingrediente.id || null,
              ingrediente: {
                uuid: ingrediente.uuid
              },
              comeu: ingrediente.status
            };
          })
        };
      })
  };

  return { ...rotina };
};

export function getConsistenciaCoco() {
  const lista = [
    {
      value: 'Líquido',
      label: 'Líquido'
    },
    {
      value: 'Pastoso',
      label: 'Pastoso'
    },
    {
      value: 'Sólido',
      label: 'Sólido'
    }
  ];

  return lista;
}

export function getDataFimCuidado(dataInicio, duracao) {
  if (duracao && duracao !== '00:00') {
    var fim = new Date(dataInicio);
    var hms = duracao; // your input string
    var a = hms.split(':'); // split it at the colons
    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    var seconds = +a[0] * 60 * 60 + +a[1] * 60;

    fim.setSeconds(fim.getSeconds() + seconds);
    return fim;
  } else {
    return dataInicio;
  }
}

export function getDuracao(dataInicio, dataFim) {
  if (dataInicio !== null && dataFim !== null) {
    const dInicio = new Date(dataInicio);
    const dFim = new Date(dataFim);

    var seconds = Math.floor(dFim.getTime() / 1000) - Math.floor(dInicio.getTime() / 1000);
    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    var duracao =
      ('0' + Math.floor(seconds / 3600)).slice(-2) +
      ':' +
      ('0' + Math.floor((seconds % 3600) / 60)).slice(-2);
    return duracao;
  } else {
    return '00:00';
  }
}

function getDisciplinas(disciplinas, atividades) {
  var disciplinasDict = {};

  (atividades || []).forEach((atividade) => {
    disciplinasDict[atividade.disciplina.uuid] = {
      observacao: atividade.descricao,
      id_atividade: atividade.id
    };
  });

  return disciplinas.map((disciplina) => {
    disciplina.id_atividade = disciplinasDict[disciplina.uuid]
      ? disciplinasDict[disciplina.uuid].id_atividade
      : null;
    disciplina.observacao = disciplinasDict[disciplina.uuid]
      ? disciplinasDict[disciplina.uuid].observacao
      : '';

    return disciplina;
  });
}

function getCardapios(cardapios, cardapiosAluno) {
  var cardapiosAlunoDict = {};

  (cardapiosAluno || []).forEach((cardapioAluno) => {
    cardapiosAlunoDict[cardapioAluno.cardapio.uuid] = cardapioAluno;

    cardapiosAlunoDict[cardapioAluno.cardapio.uuid].ingredientesDict = {};

    (cardapioAluno.ingredientes || []).forEach((ingrediente) => {
      cardapiosAlunoDict[cardapioAluno.cardapio.uuid].ingredientesDict[
        ingrediente.ingrediente.uuid
      ] = ingrediente;
    });
  });

  const retorno = cardapios.map((cardapio) => {
    cardapio.value.id = cardapiosAlunoDict[cardapio.value.uuid]
      ? cardapiosAlunoDict[cardapio.value.uuid].id
      : null;

    cardapio.value.quantidade = cardapiosAlunoDict[cardapio.value.uuid]
      ? cardapiosAlunoDict[cardapio.value.uuid].qt_comeu
      : null;

    cardapio.value.nao_planejados = cardapiosAlunoDict[cardapio.value.uuid]
      ? cardapiosAlunoDict[cardapio.value.uuid].observacao_nao_planejado
      : '';

    cardapio.value.ingredientes = cardapio.value.ingredientes.map((ingrediente) => {
      ingrediente.id = cardapiosAlunoDict[cardapio.value.uuid]
        ? cardapiosAlunoDict[cardapio.value.uuid].ingredientesDict[ingrediente.uuid].id
        : null;

      ingrediente.status = cardapiosAlunoDict[cardapio.value.uuid]
        ? cardapiosAlunoDict[cardapio.value.uuid].ingredientesDict[ingrediente.uuid].comeu
        : 'COMEU';

      if (cardapiosAlunoDict[cardapio.value.uuid]) {
        delete cardapiosAlunoDict[cardapio.value.uuid].ingredientesDict[ingrediente.uuid];
      }
      return ingrediente;
    });

    return cardapio;
  });

  return retorno;
}

function getData(data, h, m, s) {
  const data_retorno = new Date(data.getTime());
  data_retorno.setHours(h, m, s);
  return data_retorno;
}
