import React from 'react';

const iconesDict = {
  coordenacao: {
    value: 'coordenacao',
    label: <img height="27" alt="logo_coordenacao" src={'/imgs/ludica_ico-coordenacao.svg'} />
  },
  nutricao: {
    value: 'nutricao',
    label: <img height="27" alt="logo_nutricao" src={'/imgs/ludica_ico-cozinha.svg'} />
  },
  diretoria: {
    value: 'diretoria',
    label: <img height="27" alt="logo_diretoria" src={'/imgs/ludica_ico-diretoria.svg'} />
  },
  enfermaria: {
    value: 'enfermaria',
    label: <img height="27" alt="logo_enfermaria" src={'/imgs/ludica_ico-enfermaria.svg'} />
  },
  secretaria: {
    value: 'secretaria',
    label: <img height="27" alt="logo_secretaria" src={'/imgs/ludica_ico-secretaria.svg'} />
  },
  servicos: {
    value: 'servicos',
    label: <img height="27" alt="logo_servicos" src={'/imgs/ludica_ico-serv-gerais.svg'} />
  }
};

const icones = Object.keys(iconesDict).map(function (v) {
  return iconesDict[v];
});

const getIconeByName = (name) => {
  return iconesDict[name];
};

const convertFuncionalidadeEmTO = (funcionalidade) => {
  if (funcionalidade !== undefined) {
    if (Array.isArray(funcionalidade)) {
      return funcionalidade.map(convertFuncionalidadeEmTO);
    } else {
      let to = {
        value: funcionalidade.id,
        label: funcionalidade.descricao
      };
      return { ...to };
    }
  }
};

const convertSetorEmTO = (setor) => {
  if (setor !== undefined) {
    if (Array.isArray(setor)) {
      return setor.map(convertSetorEmTO);
    } else {
      let to = {
        uuid: setor.uuid,
        nome: setor.nome,
        funcionalidades: convertFuncionalidadeEmTO(setor.funcionalidades),
        data_cadastro: setor.data_cadastro,
        data_ultima_atualizacao: setor.data_ultima_atualizacao,
        icone: getIconeByName(setor.icone)
      };
      return { ...to };
    }
  }
};

const convertTOEmFuncionalidade = (to) => {
  if (to !== undefined) {
    if (Array.isArray(to)) {
      return to.map(convertTOEmFuncionalidade);
    } else {
      if (to !== undefined && to !== null) {
        let funcionalidade = {
          id: to.value,
          descricao: to.label
        };
        return { ...funcionalidade };
      } else {
        let funcionalidade = {
          id: undefined,
          descricao: undefined
        };
        return { ...funcionalidade };
      }
    }
  }
};

const convertTOEmSetor = (to) => {
  if (to !== undefined) {
    if (Array.isArray(to)) {
      return to.map(convertTOEmSetor);
    } else {
      let setor = {
        uuid: to.uuid,
        nome: to.nome,
        funcionalidades: convertTOEmFuncionalidade(to.funcionalidades),
        data_cadastro: to.data_cadastro,
        data_ultima_atualizacao: to.data_ultima_atualizacao,
        icone: to.icone
      };
      return { ...setor };
    }
  }
};

export const setorHelper = {
  convertTOEmSetor,
  convertSetorEmTO,
  convertFuncionalidadeEmTO,
  convertTOEmFuncionalidade,
  icones
};
