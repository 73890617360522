import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faEnvelope,
  faEnvelopeOpen,
  faAngleDoubleLeft,
  faComments,
  faCommentDots,
  faMailBulk,
  faAngleDoubleRight,
  faCarrot,
  faAllergies,
  faUtensils,
  faFileImage,
  faFileVideo,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faFileAlt,
  faFilePdf,
  faPaperclip,
  faDrumstickBite,
  faTasks,
  faFileArchive,
  faInbox
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faEnvelope,
  faEnvelopeOpen,
  faAngleDoubleLeft,
  faComments,
  faCommentDots,
  faMailBulk,
  faAngleDoubleRight,
  faCarrot,
  faAllergies,
  faUtensils,
  faFileImage,
  faFileVideo,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faFileAlt,
  faFilePdf,
  faPaperclip,
  faDrumstickBite,
  faTasks,
  faFileArchive,
  faInbox
);
