import { gql } from '@apollo/client';

export const FRAGMENT_COMUNICADO_PARTS = gql`
  fragment ComunicadoParts on ComunicadoNode {
    id
    pk
    uuid
    titulo
    destinatariosTodos
    permiteResposta
    dataCriacao
    dataRevisao
    dataDisponivel
    quantidadeAnexos
    mensagem
    quantidadeDestinatarios
    status {
      id
      descricao
      pk
    }
    funcionarioCadastro {
      id
      pk
      nome
    }
    funcionarioRevisao {
      id
      pk
      nome
    }
    setorCadastro {
      id
      pk
      uuid
      nome
      icone
    }
    setorRevisao {
      id
      pk
      uuid
      nome
      icone
    }
  }
`;

export const FRAGMENT_ANEXO_COMUNICADO_PARTS = gql`
  fragment AnexoComunicadoParts on ComunicadoAnexoNode {
    id
    pk
    mimetype
    nome
    tamanho
    uri
  }
`;

const COMUNICADOS_PAGINADO = gql`
  query ComunicadoPaginado(
    $tamanhoPagina: Int!
    $cursor: String
    $comunicadoFilter: ComunicadoFilter
  ) {
    comunicados(
      first: $tamanhoPagina
      after: $cursor
      sort: [DATA_CRIACAO_DESC]
      filter: $comunicadoFilter
    ) {
      totalCount
      edgeCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          uuid
          titulo
          mensagem
          destinatariosTodos
          dataCriacao
          dataRevisao
          dataDisponivel
          funcionarioCadastro {
            id
            uuid
            nome
            pk
          }
          setorCadastro {
            id
            uuid
            nome
            pk
          }
          status {
            id
            descricao
            pk
          }
          pk
          quantidadeAnexos
          quantidadeDestinatarios
        }
        cursor
        primaryKey
      }
    }
  }
`;

const FIND_COMUNICADO_ID = gql`
  ${FRAGMENT_COMUNICADO_PARTS}
  ${FRAGMENT_ANEXO_COMUNICADO_PARTS}
  query FindComunicadoID($id: ID!) {
    node(id: $id) {
      ... on ComunicadoNode {
        ...ComunicadoParts
        anexos {
          edges {
            node {
              ...AnexoComunicadoParts
            }
          }
        }
      }
    }
  }
`;

const COMUNICADOS_DESTINATARIOS_POR_UUID_COMUNICADO = gql`
  query ComunicadoDestinatariosPorUUIDComunicado($uuidComunicado: String!) {
    comunicadoDestinatario(filter: { comunicadoUuid: $uuidComunicado }) {
      edges {
        node {
          id
          dataRecebimento
          dataLeitura
          dataCiente
          dataEnvioPush
          responsavel {
            id
            uuid
            nome
            pk
          }
          aluno {
            id
            pk
            uuid
            nome
            turmaVigente {
              id
              pk
              uuid
              nome
            }
          }
          comunicado {
            id
            uuid
          }
          pk
        }
        cursor
        primaryKey
      }
      totalCount
      edgeCount
    }
  }
`;

export { COMUNICADOS_PAGINADO, FIND_COMUNICADO_ID, COMUNICADOS_DESTINATARIOS_POR_UUID_COMUNICADO };
