import { dateHelper } from './';

const convertFuncionarioEmTO = (funcionario) => {
  if (funcionario !== undefined) {
    if (Array.isArray(funcionario)) {
      return funcionario.map(convertFuncionarioEmTO);
    } else {
      let to = {
        uuid: funcionario.uuid,
        nome: funcionario.nome,
        email: funcionario.email,
        foto: funcionario.foto,
        cpf: funcionario.cpf,
        dataNascimento: funcionario.data_nascimento
          ? dateHelper.parseDataTextoEmDataObject(funcionario.data_nascimento)
          : null,
        observacao: funcionario.informacoes_adicionais || '',
        setor: {
          value: funcionario.setor ? funcionario.setor.uuid : null,
          label: funcionario.setor ? funcionario.setor.nome : null
        },
        cep: funcionario.endereco ? funcionario.endereco.cep : '',
        bairro: funcionario.endereco ? funcionario.endereco.bairro : '',
        logradouro: funcionario.endereco ? funcionario.endereco.logradouro : '',
        numero: funcionario.endereco ? funcionario.endereco.numero : '',
        complemento: funcionario.endereco ? funcionario.endereco.complemento : '',
        ufMuni: {
          municipio: {
            label: funcionario.endereco ? funcionario.endereco.municipio.nome : '',
            value: funcionario.endereco ? funcionario.endereco.municipio.codigo : ''
          },
          uf: {
            label: funcionario.endereco ? funcionario.endereco.municipio.uf.nome : '',
            value: funcionario.endereco ? funcionario.endereco.municipio.uf.codigo : ''
          }
        },
        telefones: [],
        tipo_funcionario: {
          id: funcionario.tipo_funcionario ? funcionario.tipo_funcionario.id : undefined,
          descricao: funcionario.tipo_funcionario ? funcionario.tipo_funcionario.descricao : ''
        }
      };

      if (funcionario.telefones && funcionario.telefones.length > 0) {
        funcionario.telefones.forEach((tel) => {
          const telefone = {
            ddd: tel.ddd,
            numero: tel.numero,
            aceitaContato: tel.aceita_contato_mensageiro,
            mensageiro: tel.mensageiro
          };
          to.telefones.push(telefone);
        });
      } else {
        to.telefones.push({
          ddd: '',
          numero: '',
          aceitaContato: true,
          mensageiro: ''
        });
      }
      return { ...to };
    }
  }
};

const convertTOEmFuncionario = (to) => {
  if (to !== undefined) {
    if (Array.isArray(to)) {
      return to.map(convertTOEmFuncionario);
    } else {
      let funcionario = {
        uuid: to.uuid,
        nome: to.nome,
        email: to.email.toLowerCase(),
        foto: to.foto,
        cpf: String(to.cpf).replace(/[^\d]+/g, ''),
        data_nascimento: dateHelper.formatDate(to.dataNascimento),
        informacoes_adicionais: to.observacao,
        setor:
          to.setor !== undefined
            ? {
                uuid: to.setor.value
              }
            : undefined,
        endereco: {
          cep: String(to.cep).replace(/[^\d]+/g, ''),
          bairro: to.bairro,
          logradouro: to.logradouro,
          numero: to.numero,
          complemento: to.complemento,
          municipio: {
            codigo: to.ufMuni.municipio.value
          }
        },
        tipo_funcionario: {
          id: to.tipo_funcionario.id,
          descricao: to.tipo_funcionario.descricao
        },
        telefones: []
      };

      if (to.telefones) {
        to.telefones.forEach((tel) => {
          const telefone = {
            ddd: tel.ddd,
            numero: String(tel.numero).replace(/[^\d]+/g, ''),
            aceita_contato_mensageiro: tel.aceitaContato,
            mensageiro: tel.mensageiro
          };
          funcionario.telefones.push(telefone);
        });
      }

      return { ...funcionario };
    }
  }
};

export const funcionarioHelper = {
  convertFuncionarioEmTO,
  convertTOEmFuncionario
};
