// import { asyncReducer } from 'redux-promise-middleware-actions';
import {
  gravarFuncionario,
  obterListaFuncionarios,
  removerFuncionario,
  obterFuncionario,
  alterarFuncionario,
  obterFuncionarioPorEmail,
  obterDisciplinasFuncionario
} from '../_actions';

// export const salvarReducer = asyncReducer(gravarFuncionario);
// export const obterTodosReducer = asyncReducer(obterTodos);

const initialState = {
  funcionarios: [],
  funcionario: {},
  turmasDisciplina: []
};
export const funcionarioReducer = (state = initialState, action) => {
  switch (action.type) {
    case String(gravarFuncionario.fulfilled):
      return { ...state, funcionario: action.payload.data };
    case String(obterListaFuncionarios.fulfilled):
      return { ...state, funcionarios: action.payload.data };
    case String(obterFuncionario.fulfilled):
      return { ...state, funcionario: action.payload.data };
    case String(removerFuncionario.fulfilled):
      return { ...state, funcionarios: [] };
    case String(obterDisciplinasFuncionario.fulfilled):
      return { ...state, turmasDisciplina: action.payload.data };
    case String(alterarFuncionario.fulfilled):
      return { ...state, funcionario: action.payload.data };
    case String(obterFuncionarioPorEmail.fulfilled):
      return {
        ...state,
        funcionario: action.payload.data.length > 0 ? action.payload.data[0] : null
      };
    default:
      return state;
  }
};
