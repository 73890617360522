import {
  salvarTurma,
  obterListaTurmas,
  obterListaTurmasComAluno,
  obterTurma,
  obterTurmasPorAno,
  obterTurmasPorNome,
  atualizarTurma,
  removerTurma,
  obterListaAlunosPorTurmas,
  migrarTurma,
  obterAnosTurmas
} from '../_actions';

const initialState = { turma: {}, turmas: [], alunosPorTurmas: [] };

export const turmaReducer = (state = initialState, action) => {
  switch (action.type) {
    case String(salvarTurma.fulfilled):
      return { ...state, turma: action.payload.data };
    case String(obterListaTurmas.fulfilled):
      return { ...state, turmas: action.payload.data };
    case String(obterListaTurmasComAluno.fulfilled):
      return { ...state, turmas: action.payload.data };
    case String(obterTurma.fulfilled):
      return { ...state, turma: action.payload.data };
    case String(obterTurmasPorAno.fulfilled):
      return { ...state, turmas: action.payload.data };
    case String(obterTurmasPorNome.fulfilled):
      return { ...state, turmas: action.payload.data };
    case String(atualizarTurma.fulfilled):
      return { ...state, turma: action.payload.data };
    case String(removerTurma.fulfilled):
      return { ...state, turmas: [] };
    case String(obterListaAlunosPorTurmas.fulfilled):
      return { ...state, alunosPorTurmas: action.payload.data };
    case String(migrarTurma.fulfilled):
      return { ...state, migrarTurma: action.payload.data };
    case String(obterAnosTurmas.fulfilled):
      return { ...state, anosTurmas: action.payload.data };
    default:
      return state;
  }
};
