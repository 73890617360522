//funcao retirada do site da Receita
function r(n) {
  if (n === '00000000000') return false;
  var r = 0,
    t = 0;
  var i = 0;
  for (i = 1; i <= 9; i++) {
    r = r + parseInt(n.substring(i - 1, i)) * (11 - i);
  }
  if (
    ((t = (r * 10) % 11), (t === 10 || t === 11) && (t = 0), t !== parseInt(n.substring(9, 10)))
  ) {
    return false;
  }
  for (r = 0, i = 1; i <= 10; i++) {
    r = r + parseInt(n.substring(i - 1, i)) * (12 - i);
  }
  return ((t = (r * 10) % 11),
  (t === 10 || t === 11) && (t = 0),
  t !== parseInt(n.substring(10, 11)))
    ? false
    : true;
}

function todosDigitosIguais(cpf) {
  for (let i = 0; i < 10; i++) {
    if (cpf === Array(12).join(String(i))) {
      return true;
    }
  }
  return false;
}

/**
 * Função que valida um cpf.
 * @param {*} cpf
 */
export default function validaCpf(cpf) {
  if (cpf) {
    cpf = cpf.toString().replace(/\D+/g, '');
    return cpf.length === 11 && !todosDigitosIguais(cpf) && r(cpf) ? !0 : !1;
  }
  return false;
}
