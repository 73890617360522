import * as Yup from 'yup';
import { CAMPO_OBRIGATORIO, CAMPO_INVALIDO } from '../components/Mensagens';
import validaCpf from '../_validators/cpf.validator';

const telefone = {
  ddd: Yup.string()
    .required(CAMPO_OBRIGATORIO.format('DDD'))
    .matches(/^[1-9]{2}$/, {
      message: CAMPO_INVALIDO.format('DDD'),
      excludeEmptyString: true
    }),
  numero: Yup.string()
    .required(CAMPO_OBRIGATORIO.format('Telefone'))
    .matches(/^[1-9][0-9]{3,4}-?[0-9]{4}$/, {
      message: CAMPO_INVALIDO.format('Telefone'),
      excludeEmptyString: true
    })
};

const validacaoTelefone = Yup.array()
  .min(1, 'Preencha ao menos um telefone.')
  .of(
    Yup.object().shape({
      ...telefone,
      aceitaContato: Yup.boolean(),
      mensageiro: Yup.string()
        .when('aceitaContato', {
          is: true,
          then: Yup.string().required(CAMPO_OBRIGATORIO.format('Mensageiro'))
        })
        .nullable()
    })
  );

const validacaoUf = Yup.object().shape({
  uf: Yup.object().required(CAMPO_OBRIGATORIO.format('UF')).nullable(),
  municipio: Yup.object().required(CAMPO_OBRIGATORIO.format('Município')).nullable()
});

const validacaoCadastroBasico = {
  nome: Yup.string('Insira um nome').required(CAMPO_OBRIGATORIO.format('nome')),
  email: Yup.string('Insira um e-mail')
    .email('Insira um e-mail válido.')
    .required(CAMPO_OBRIGATORIO.format('e-mail')),
  dataNascimento: Yup.date('Insira uma data')
    .required(CAMPO_OBRIGATORIO.format('data de nascimento'))
    .nullable(),
  cpf: Yup.string('Insira um cpf')
    .required(CAMPO_OBRIGATORIO.format('CPF'))
    .test('valido', 'Cpf inválido', (valor) => {
      return validaCpf(valor);
    }),
  cep: Yup.string('Insira um cep').required(CAMPO_OBRIGATORIO.format('CEP')),
  logradouro: Yup.string('Insira um logradouro').required(CAMPO_OBRIGATORIO.format('logradouro')),
  bairro: Yup.string('Insira um bairro').required(CAMPO_OBRIGATORIO.format('bairro')),
  ufMuni: validacaoUf,
  telefones: validacaoTelefone
};

const validacaoCadastroBasicoResponsavel = {
  nome: Yup.string('Insira um nome').required(CAMPO_OBRIGATORIO.format('nome')),
  email: Yup.string('Insira um e-mail')
    .email('Insira um e-mail válido.')
    .required(CAMPO_OBRIGATORIO.format('e-mail')),
  telefones: validacaoTelefone
};

const validaVazioRichText = (rText) => {
  if (rText) {
    const insideValue = rText
      .replaceAll('<p>', '')
      .replaceAll('</p>', '')
      .replaceAll('<br>', '')
      .trim();
    return insideValue.length > 0;
  }
  return false;
};

const validacaoEmail = Yup.object({
  titulo: Yup.string().test('vazio', CAMPO_OBRIGATORIO.format('título'), (valor) =>
    valor === undefined ? false : valor.trim()
  ),

  data_disponivel: Yup.date('Insira uma data válida')
    .when('envio_imediato', {
      is: false,
      then: Yup.date().required(CAMPO_OBRIGATORIO.format('data e hora de envio'))
    })
    .nullable(),

  destinatarios_todos: Yup.boolean(),
  destinatarios: Yup.array().when('destinatarios_todos', {
    is: false,
    then: Yup.array().min(1, 'Preencha ao menos um destinatário.')
  }),

  mensagem: Yup.string().test('vazio', CAMPO_OBRIGATORIO.format('mensagem'), (valor) => {
    return validaVazioRichText(valor);
  })
});

export const validacaoHelper = {
  validaCpf,
  validacaoTelefone,
  validacaoUf,
  validacaoEmail,
  validacaoCadastroBasico,
  validacaoCadastroBasicoResponsavel
};
