import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  nested: {
    padding: 5,
    paddingLeft: theme.spacing(6)
  }
}));

const SubItemMenu = (props) => {
  const classes = useStyles();

  return props.render === undefined || props.render === true ? (
    <ListItem
      button
      id={props.id}
      className={props.nested && classes.nested}
      component={props.component}
      to={props.to}
      style={props.style}
      onClick={props.onClick}>
      <ListItemIcon>{props.icon}</ListItemIcon>
      <ListItemText primary={props.titulo} />
    </ListItem>
  ) : (
    ''
  );
};

export default SubItemMenu;
