import { httpHelper } from '../_helpers';

export const disciplinaService = {
  obterTodasDisciplinas,
  gravarDisciplina,
  removerDisciplina,
  obterDisciplina,
  alterarDisciplina
};

function obterTodasDisciplinas(status = true) {
  return httpHelper.get('/seguro/disciplinas/', { params: { status } });
}

function gravarDisciplina(dados) {
  return httpHelper.post('/seguro/disciplinas/', { data: dados });
}

function obterDisciplina(uuid) {
  return httpHelper.get(`/seguro/disciplinas/${uuid}`);
}

function alterarDisciplina(dados) {
  return httpHelper.put(`/seguro/disciplinas/`, { data: dados });
}

function removerDisciplina(uuid) {
  return httpHelper.del(`/seguro/disciplinas/${uuid}`);
}
