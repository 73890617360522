const convertFuncionalidadeEmTO = (funcionalidade) => {
  if (funcionalidade !== undefined) {
    if (Array.isArray(funcionalidade)) {
      return funcionalidade.map(convertFuncionalidadeEmTO);
    } else {
      let to = {
        value: funcionalidade.id,
        label: funcionalidade.descricao
      };
      return { ...to };
    }
  }
};

const convertInformativoEmTO = (informativo) => {
  if (informativo !== undefined) {
    if (Array.isArray(informativo)) {
      return informativo.map(convertInformativoEmTO);
    } else {
      let to = {
        uuid: informativo.uuid,
        nome: informativo.nome,
        avatar: informativo.avatar,
        link_externo: informativo.link_externo,
        informativo: informativo.informativo,
        frequencia: convertTipoFrequenciaEmTO(informativo.frequencia),
        funcionalidade: convertFuncionalidadeEmTO(informativo.funcionalidade)
      };
      return { ...to };
    }
  }
};

const convertTipoFrequenciaEmTO = (tipoFrequencia) => {
  if (tipoFrequencia !== undefined) {
    if (Array.isArray(tipoFrequencia)) {
      return tipoFrequencia.map(convertTipoFrequenciaEmTO);
    } else {
      let valor = tipoFrequencia;
      if (valor === 'ATE_CIENCIA') {
        valor = 'Até ciência';
      } else if (valor === 'SEMPRE') {
        valor = 'Sempre';
      } else {
        valor = tipoFrequencia;
      }

      let to = {
        value: tipoFrequencia,
        label: valor
      };
      return { ...to };
    }
  }
};

const convertTOEmFuncionalidade = (to) => {
  if (to !== undefined) {
    if (Array.isArray(to)) {
      return to.map(convertTOEmFuncionalidade);
    } else {
      if (to !== undefined && to !== null) {
        let funcionalidade = {
          id: to.value,
          descricao: to.label
        };
        return { ...funcionalidade };
      } else {
        let funcionalidade = {
          id: undefined,
          descricao: undefined
        };
        return { ...funcionalidade };
      }
    }
  }
};

const convertTOEmTipoFrequencia = (to) => {
  if (to !== undefined) {
    if (Array.isArray(to)) {
      return to.map(convertTOEmTipoFrequencia);
    } else {
      if (to !== undefined && to !== null) {
        let tipoFrequencia = {
          id: to.value,
          descricao: to.label
        };
        return tipoFrequencia.id;
      } else {
        let tipoFrequencia = {
          id: undefined,
          descricao: undefined
        };
        return { ...tipoFrequencia };
      }
    }
  }
};

const convertTOEmInformativo = (to) => {
  if (to !== undefined) {
    if (Array.isArray(to)) {
      return to.map(convertTOEmInformativo);
    } else {
      let informativo = {
        uuid: to.uuid,
        nome: to.nome,
        avatar: to.avatar,
        informativo: to.informativo,
        link_externo: to.link_externo,
        frequencia: convertTOEmTipoFrequencia(to.frequencia),
        funcionalidade: convertTOEmFuncionalidade(to.funcionalidade)
      };
      return { ...informativo };
    }
  }
};

export const informativoHelper = {
  convertFuncionalidadeEmTO,
  convertTOEmFuncionalidade,
  convertInformativoEmTO,
  convertTOEmInformativo,
  convertTipoFrequenciaEmTO,
  convertTOEmTipoFrequencia
};
