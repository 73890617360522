/**
 * Parse das mensagens recebidas pelo Firebase.
 * https://firebase.google.com/docs/reference/js/firebase.auth.Error
 * https://firebase.google.com/docs/reference/js/firebase.auth.Auth
 */

var mapMensagensFirebase = new Map();
mapMensagensFirebase.set('auth/invalid-email', 'O Formato do e-mail é inválido.');
mapMensagensFirebase.set(
  'auth/wrong-password',
  'Senha inválida ou o usuário não possui senha cadastrada.'
);
mapMensagensFirebase.set('auth/user-disabled', 'Conta de usuário desativada.');
mapMensagensFirebase.set('auth/user-not-found', 'E-mail não cadastrado ou o usuário foi removido.');
mapMensagensFirebase.set('auth/weak-password', 'A senha deve ter ao menos 6 dígitos.');
mapMensagensFirebase.set(
  'auth/expired-action-code',
  'Código de ativação expirado. Por favor re-inicie o processo de redefinição de senha.'
);
mapMensagensFirebase.set('auth/invalid-action-code', 'Código de ativação inválido.');
mapMensagensFirebase.set(
  'auth/operation-not-allowed',
  'Erro ao se autenticar com e-mail e senha. Por favor entre em contato com o suporte.'
);
mapMensagensFirebase.set(
  'auth/network-request-failed',
  'Erro de comunicação com o Google Firebase.'
);
mapMensagensFirebase.set(
  'auth/user-token-expired',
  'Sessão expirada. Por favor realizar novo login.'
);
mapMensagensFirebase.set(
  'auth/invalid-user-token',
  'Sessão expirada. Por favor realizar novo login.'
);

export default mapMensagensFirebase;
