export const loginStyles = (theme) => ({
  main: {
    width: 'auto',
    display: 'flex', // Fix IE 11 issue.
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      justifyContent: 'center'
    },
    alignItems: 'center',
    flexDirection: 'column',
    height: '100vh',
    backgroundColor: 'rgb(238,238,238)'
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '680px',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {}
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(3)
  },
  /////
  logoImage: {
    height: '80px',
    alignSelf: 'center'
  },
  paperBase: {
    display: 'flex',
    flexDirection: 'column',
    width: '360px',
    [theme.breakpoints.down(480 + theme.spacing(3 * 2))]: {
      width: '100%'
    }
  },
  paperSpacing: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  }
});
