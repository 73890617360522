import { createAsyncAction } from 'redux-promise-middleware-actions';
import { funcionarioService } from '../_services';

export const gravarFuncionario = createAsyncAction('FUNCIONARIO_SALVAR', async (funcionario) => {
  return await funcionarioService.persistir(funcionario);
});

export const obterListaFuncionarios = createAsyncAction(
  'FUNCIONARIO_OBTER_LISTA',
  async (obter_professores) => {
    return await funcionarioService.obter(undefined, true, obter_professores);
  }
);

export const obterFuncionarioPorEmail = createAsyncAction(
  'FUNCIONARIO_OBTER_EMAIL',
  async (email) => {
    return await funcionarioService.obterPorEmail(email);
  }
);

export const obterFuncionario = createAsyncAction(
  'FUNCIONARIO_OBTER',
  async (uuid, obter_professores = false) => {
    return await funcionarioService.obter(uuid, true, obter_professores);
  }
);

export const obterDisciplinasFuncionario = createAsyncAction(
  'FUNCIONARIO_OBTER_DISCIPLINAS',
  async (uuid) => {
    return await funcionarioService.obterDisciplinasFuncionario(uuid);
  }
);

export const removerFuncionario = createAsyncAction('FUNCIONARIO_REMOVER', async (uuid) => {
  return await funcionarioService.remover(uuid);
});

export const alterarFuncionario = createAsyncAction(
  'FUNCIONARIO_ALTERAR',
  async (uuid, funcionario) => {
    return await funcionarioService.persistir(funcionario);
  }
);
