import { sendPasswordResetEmail } from "firebase/auth";

import { firebaseHelper } from "../_helpers";

const recuperarSenha = (email) => {
  return sendPasswordResetEmail(firebaseHelper.auth, email);
};

export const esqueciSenhaService = {
  recuperarSenha,
};
