import { dateHelper } from './date.helper';

const convertAlunoEmTO = (aluno) => {
  if (aluno !== undefined) {
    if (Array.isArray(aluno)) {
      return aluno.map(convertAlunoEmTO);
    } else {
      let to = {
        uuid: aluno.uuid,
        nome: aluno.nome,
        dataNascimento: aluno.data_nascimento
          ? dateHelper.parseDataTextoEmDataObject(aluno.data_nascimento)
          : null,
        observacao: aluno.observacoes,
        foto: aluno.foto,
        plano: aluno.dados_complementares ? aluno.dados_complementares.plano_saude : '',
        contatoEmergencia: aluno.dados_complementares
          ? { ...aluno.dados_complementares.contato_emergencia }
          : '',
        nomePediatra: aluno.dados_complementares
          ? aluno.dados_complementares.pediatra
            ? aluno.dados_complementares.pediatra.nome
            : ''
          : '',
        telefonePediatra: aluno.dados_complementares
          ? aluno.dados_complementares.pediatra
            ? { ...aluno.dados_complementares.pediatra.telefone }
            : ''
          : '',
        tipoSanguineo: {
          value: aluno.dados_complementares ? aluno.dados_complementares.tipo_sanguineo : '',
          label: aluno.dados_complementares ? aluno.dados_complementares.tipo_sanguineo : ''
        },
        alergico: !!aluno.alergico,
        alergias: aluno.dados_complementares
          ? [...(aluno.dados_complementares.alergias || [])]
          : [],
        doencasExistentes: aluno.dados_complementares
          ? [...(aluno.dados_complementares.doencas || [])]
          : [],
        autorizadasBuscar: aluno.dados_complementares
          ? [...(aluno.dados_complementares.responsaveis_retirar || [])]
          : [],
        responsaveis: (aluno.responsaveis || []).map((resp) => ({
          value: resp.uuid,
          label: resp.nome
        })),
        acesso_bloqueado: !!aluno.acesso_bloqueado
      };

      return { ...to };
    }
  }
};

const convertTOEmAluno = (to) => {
  if (to !== undefined) {
    if (Array.isArray(to)) {
      return to.map(convertTOEmAluno);
    } else {
      const aluno = {
        uuid: to.uuid,
        foto: to.foto,
        nome: to.nome,
        observacoes: to.observacao,
        data_nascimento: dateHelper.formatDate(to.dataNascimento),
        responsaveis:
          to.responsaveis == null
            ? ''
            : to.responsaveis.map((resp) => ({
                uuid: resp.value,
                nome: resp.label
              })),
        alergico: to.alergico,
        dados_complementares: {
          alergias: [...to.alergias],
          doencas: [...to.doencasExistentes],
          plano_saude: to.plano,
          contato_emergencia: {
            ddd: to.contatoEmergencia.ddd || null,
            numero: to.contatoEmergencia.numero || null
          },
          pediatra: {
            nome: to.nomePediatra,
            telefone: {
              ddd: to.telefonePediatra.ddd || null,
              numero: to.telefonePediatra.numero || null
            }
          },
          tipo_sanguineo: to.tipoSanguineo.value,
          responsaveis_retirar: [...to.autorizadasBuscar]
        },
        acesso_bloqueado: to.acesso_bloqueado
      };

      return { ...aluno };
    }
  }
};

export const alunoHelper = {
  convertAlunoEmTO,
  convertTOEmAluno
};
