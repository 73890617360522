import {
  gravarInformativo,
  obterListaInformativos,
  obterInformativo,
  removerInformativo,
  alterarInformativo,
  obterListaFuncionalidadesInformativo,
  obterListaTipoFrequencia
} from '../_actions';

const initialState = {
  informativos: [],
  informativo: {},
  funcionalidades: [],
  tiposfrequencia: []
};

export const informativoReducer = (state = initialState, action) => {
  switch (action.type) {
    case String(gravarInformativo.fulfilled):
      return { ...state, informativo: action.payload.data };
    case String(obterListaInformativos.fulfilled):
      return { ...state, informativos: action.payload.data };
    case String(obterInformativo.fulfilled):
      return { ...state, informativo: action.payload.data };
    case String(removerInformativo.fulfilled):
      return { ...state, informativo: {} };
    case String(alterarInformativo.fulfilled):
      return { ...state, informativo: action.payload.data };
    case String(obterListaFuncionalidadesInformativo.pending):
      return { ...state, funcionalidade: [] };
    case String(obterListaFuncionalidadesInformativo.fulfilled):
      return { ...state, funcionalidades: action.payload.data };
    case String(obterListaTipoFrequencia.pending):
      return { ...state, tiposfrequencia: [] };
    case String(obterListaTipoFrequencia.fulfilled):
      return { ...state, tiposfrequencia: action.payload.data };
    default:
      return state;
  }
};
