import {
  signInWithEmailAndPassword,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from "firebase/auth";

import { firebaseHelper, httpHelper, authHelper } from "../_helpers";

/**
 * Autentica com o firebase e depois obtém o token na api.
 * @param {*} email
 * @param {*} senha
 */
const login = async (email, senha) => {
  await signInWithEmailAndPassword(firebaseHelper.auth, email, senha);
  const firebaseIdToken = await firebaseHelper.auth.currentUser.getIdToken();
  await httpHelper
    .post("/seguro/autorizacao/funcionario/token/gerar", {
      data: { token: firebaseIdToken },
    })
    .then((response) => {
      authHelper.setUserTokens(response.data);
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Envia a requisição de logout no sistema.
 */
const logout = () => {
  return httpHelper.get("/seguro/autorizacao/token/revogar");
};

const definirNovaSenha = async (oobCode, senha) => {
  // Verifica se o código oobCode ainda é válido para a operação
  return verifyPasswordResetCode(firebaseHelper.auth, oobCode)
    .then(() => {
      // Confirma a nova senha
      confirmPasswordReset(firebaseHelper.auth, oobCode, senha);
    })
    .catch((error) => {
      throw error;
    });
};

export const authService = {
  login,
  logout,
  definirNovaSenha,
};
