import {
  gravarCardapio,
  obterListaCardapios,
  removerCardapio,
  obterCardapio,
  alterarCardapio,
  obterAssociacaoTurma,
  pesquisarCardapios,
  pesquisaFiltro,
  obterListaCardapiosPaginada,
  pesquisaFiltroPaginado,
  obterListaCardapiosReduzida
} from '../_actions/cardapio.actions';

const initialState = {
  cardapios: [],
  cardapio: {},
  associacoesTurma: {},
  cardapios_pesquisa: [],
  listaReduzida: []
};

export const cardapioReducer = (state = initialState, action) => {
  switch (action.type) {
    case String(gravarCardapio.fulfilled):
      return { ...state, cardapio: action.payload.data };
    case String(obterListaCardapios.fulfilled):
      return { ...state, cardapios: action.payload.data };
    case String(pesquisaFiltro.fulfilled):
      return { ...state, cardapios: action.payload.data };
    case String(obterCardapio.fulfilled):
      return { ...state, cardapio: action.payload.data };
    case String(removerCardapio.fulfilled):
      return { ...state, cardapios: [] };
    case String(alterarCardapio.fulfilled):
      return { ...state, cardapio: action.payload.data };
    case String(obterAssociacaoTurma.fulfilled):
      return { ...state, associacoesTurma: action.payload.data };
    case String(pesquisarCardapios.fulfilled):
      return { ...state, cardapios_pesquisa: action.payload.data };
    case String(obterListaCardapiosPaginada.fulfilled):
      return { ...state, paginacao: action.payload.data };
    case String(pesquisaFiltroPaginado.fulfilled):
      return { ...state, paginacao: action.payload.data };
    case String(obterListaCardapiosReduzida.fulfilled):
      return { ...state, listaReduzida: action.payload.data };
    default:
      return state;
  }
};
