import { httpHelper } from '../_helpers';
import { eventoHelper } from '../_helpers';

/**
 * Lista os Eventos pelo Ano e Status.
 * @param {*} ano
 * @param {*} status
 * @returns
 */
const listar = async (ano, status = true) => {
  const response = await httpHelper.get('/seguro/eventos/', {
    params: {
      ano: ano,
      status: status
    }
  });
  return eventoHelper.convertEventoEmTO(response ? response.data : undefined);
};

/**
 * Desativa um Evento.
 * @param {*} uuid
 * @returns
 */
const desativar = async (uuid) => {
  return httpHelper.del(`/seguro/eventos/${uuid}`);
};

/**
 * Cadastra um novo Evento.
 * @param {*} data
 * @returns
 */
const cadastrar = async (evento) => {
  return httpHelper.post('/seguro/eventos/', {
    data: eventoHelper.convertTOEmEvento(evento)
  });
};

/**
 * Cadastra um novo Evento.
 * @param {*} data
 * @returns
 */
const alterar = async (evento) => {
  return httpHelper.put('/seguro/eventos/', {
    data: eventoHelper.convertTOEmEvento(evento)
  });
};

/**
 * Obtém um Evento pelo UUID.
 * @param {*} uuid
 * @returns
 */
const obter = async (uuid) => {
  return httpHelper.get(`/seguro/eventos/${uuid}`).then((response) => {
    return eventoHelper.convertEventoEmTO(response.data);
  });
};

/**
 * Obtém os Eventos em um intervalo de data.
 * @param {*} data_inicio
 * @param {*} data_fim
 * @returns
 */
const obterPorCompetencia = async (competencia, status = true) => {
  return httpHelper
    .get('/seguro/eventos/pesquisa/competencia', {
      params: {
        valor: competencia,
        status: status
      }
    })
    .then((response) => {
      return eventoHelper.convertEventoEmTO(response.data);
    });
};

export const eventoService = {
  listar,
  desativar,
  cadastrar,
  alterar,
  obter,
  obterPorCompetencia
};
