import {
  salvarResponsavel,
  obterListaResponsaveis,
  removerResponsavel,
  obterResponsavel,
  atualizarResponsavel
} from '../_actions';

const initialState = { responsaveis: [], responsavel: {} };
export const responsavelReducer = (state = initialState, action) => {
  switch (action.type) {
    case String(salvarResponsavel.fulfilled):
      return { ...state, responsavel: action.payload.data };
    case String(obterListaResponsaveis.fulfilled):
      return { ...state, responsaveis: action.payload.data };
    case String(removerResponsavel.fulfilled):
      return { ...state, responsaveis: [] };
    case String(obterResponsavel.fulfilled):
      return { ...state, responsavel: action.payload.data };
    case String(atualizarResponsavel.fulfilled):
      return { ...state, responsavel: action.payload.data };
    default:
      return state;
  }
};
