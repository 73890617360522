import { createAsyncAction } from 'redux-promise-middleware-actions';
import { informativoService } from '../_services/informativo.service';

export const gravarInformativo = createAsyncAction('INFORMATIVO_SALVAR', async (informativo) => {
  return await informativoService.gravarInformativo(informativo);
});

export const obterListaInformativos = createAsyncAction('INFORMATIVO_OBTER_LISTA', async () => {
  return await informativoService.obterTodosInformativos();
});

export const obterInformativo = createAsyncAction('INFORMATIVO_OBTER', async (uuid) => {
  return await informativoService.obterInformativoPorUuid(uuid);
});

export const removerInformativo = createAsyncAction('INFORMATIVO_REMOVER', async (uuid) => {
  return await informativoService.removerInformativo(uuid);
});

export const alterarInformativo = createAsyncAction('INFORMATIVO_ALTERAR', async (informativo) => {
  return await informativoService.alterarInformativo(informativo);
});
