import {
  salvarAluno,
  obterListaAlunos,
  removerAluno,
  obterAluno,
  alterarAluno,
  obterListaAlunosComTurma,
  obterListaAlunoComTurmasPorResponsavel,
  pesquisarAlunosParaDesabilitar,
  habilitarAlunos,
  desabilitarAlunos,
  resetarAlunos
} from '../_actions';

const initialState = {
  alunos: [],
  aluno: {},
  alunosTurma: []
};
export const alunoReducer = (state = initialState, action) => {
  switch (action.type) {
    case String(salvarAluno.fulfilled):
      return { ...state, aluno: action.payload.data };
    case String(obterListaAlunos.fulfilled):
      return { ...state, alunos: action.payload.data };
    case String(removerAluno.fulfilled):
      return { ...state, aluno: {} };
    case String(obterAluno.fulfilled):
      return { ...state, aluno: action.payload.data };
    case String(alterarAluno.fulfilled):
      return { ...state, aluno: action.payload.data };
    case String(obterListaAlunosComTurma.fulfilled):
      return { ...state, alunosTurma: action.payload.data };
    case String(obterListaAlunoComTurmasPorResponsavel.fulfilled):
      return { ...state, alunosTurma: action.payload.data };
    case String(pesquisarAlunosParaDesabilitar.pending):
      return { ...state, alunos: [] };
    case String(pesquisarAlunosParaDesabilitar.fulfilled):
      return { ...state, alunos: action.payload.data };
    case String(desabilitarAlunos.fulfilled):
      return { ...state };
    case String(habilitarAlunos.fulfilled):
      return { ...state };
    case String(resetarAlunos.fulfilled):
      return { ...initialState };
    default:
      return state;
  }
};
