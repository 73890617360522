import * as comunicadoAction from '../_actions/comunicado.poc.actions';

const initialState = {
  comunicado: undefined,
  lstComunicados: [],
  loading: undefined,
  erro: undefined
};

export const comunicadoPocReducer = (state = initialState, action) => {
  switch (action.type) {
    // Cadastro de comunicado
    case String(comunicadoAction.cadastrar.pending):
      return {
        ...state,
        comunicado: undefined,
        loading: true,
        erro: undefined
      };
    case String(comunicadoAction.cadastrar.fulfilled):
      return {
        ...state,
        comunicado: action.payload.data,
        loading: false
      };
    case String(comunicadoAction.cadastrar.rejected):
      return {
        ...state,
        loading: false,
        erro: action.payload.response.data
      };
    // Atualizar Comunicado
    case String(comunicadoAction.atualizar.pending):
      return {
        ...state,
        comunicado: undefined,
        loading: true,
        erro: undefined
      };
    case String(comunicadoAction.atualizar.fulfilled):
      return {
        ...state,
        comunicado: action.payload.data,
        loading: false
      };
    case String(comunicadoAction.atualizar.rejected):
      return {
        ...state,
        loading: false,
        erro: action.payload.response.data
      };
    // Revisar Comunicado
    case String(comunicadoAction.revisar.pending):
      return {
        ...state,
        comunicado: undefined,
        loading: true,
        erro: undefined
      };
    case String(comunicadoAction.revisar.fulfilled):
      return {
        ...state,
        comunicado: action.payload.data,
        loading: false
      };
    case String(comunicadoAction.revisar.rejected):
      return {
        ...state,
        loading: false,
        erro: action?.payload?.response?.data
      };
    // Obter Comunicado por UUID
    case String(comunicadoAction.obterPorUUID.pending):
      return {
        ...state,
        comunicado: undefined,
        loading: true,
        erro: undefined
      };
    case String(comunicadoAction.obterPorUUID.fulfilled):
      return {
        ...state,
        comunicado: action.payload.data,
        loading: false
      };
    case String(comunicadoAction.obterPorUUID.rejected):
      return {
        ...state,
        loading: false,
        erro: action.payload.response.data
      };
    // Obter Comunicados por Status
    case String(comunicadoAction.obterPorStatus.pending):
      return {
        ...state,
        lstComunicados: [],
        loading: true,
        erro: undefined
      };
    case String(comunicadoAction.obterPorStatus.fulfilled):
      return {
        ...state,
        lstComunicados: action.payload.data,
        loading: false
      };
    case String(comunicadoAction.obterPorStatus.rejected):
      return {
        ...state,
        loading: false,
        erro: action.payload.response.data
      };

    default:
      return { ...state };
  }
};
