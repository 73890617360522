import React, { useState, useEffect } from 'react';
import { withSnackbar } from 'notistack';
import queryString from 'query-string';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import CssBaseline from '@material-ui/core/CssBaseline';
import withStyles from '@material-ui/core/styles/withStyles';

import Rotas from '../../_constants/rotas';
import mapMensagensFirebase from '../../_constants/firebase.mensagens';

import { loginStyles } from '../../components/styles/LoginStyles';
import AdornedButton from '../../components/AdornedButton';

import { authService } from '../../_services/auth.service';
import { Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  }
});

/**
 * Tela de Definir nova senha.
 * @param {*} props
 * @returns
 */
const DefinirNovaSenha = (props) => {
  const localClasses = useStyles();
  const { classes } = props;
  const [novaSenha, setNovaSenha] = useState('');
  const [repetirNovaSenha, setRepetirNovaSenha] = useState('');
  const [loading, setLoading] = useState(false);
  const [sucesso, setSucesso] = useState(false);

  useEffect(() => {
    if (
      queryString.parse(props.location.search, { ignoreQueryPrefix: true }).mode !== 'resetPassword'
    ) {
      return props.history.push(Rotas.autenticacao.login);
    }
  }, []);

  const handleChangeNovaSenha = (e) => {
    const { value } = e.target;
    setNovaSenha(value);
  };

  const handleChangeRepetirNovaSenha = (e) => {
    const { value } = e.target;
    setRepetirNovaSenha(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Obtém o código oobCode da url.
    const { oobCode, origem } = queryString.parse(props.location.search, {
      ignoreQueryPrefix: true
    });
    setLoading(true);
    authService
      .definirNovaSenha(oobCode, novaSenha)
      .then(() => {
        if (origem === 'responsavel') {
          setSucesso(true);
        } else {
          props.enqueueSnackbar('Nova senha definida!', { variant: 'success' });
          props.history.push(Rotas.autenticacao.login);
        }
      })
      .catch((error) => {
        props.enqueueSnackbar(mapMensagensFirebase.get(error?.code), {
          variant: 'error'
        });
      })
      .finally(() => setLoading(false));
  };

  const form = () => {
    return (
      <>
        <img
          height="80"
          alt="logo"
          src={require('../../assets/imgs/ludica_logo_horizontalxhdpi_2x.png')}
        />
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            name="novaSenha"
            label="Nova senha"
            fullWidth
            required
            value={novaSenha}
            type="password"
            onChange={handleChangeNovaSenha}
            disabled={loading}
            helperText="Insira aqui sua NOVA senha (mínimo de 6 dígitos)"
          />
          <br />
          <TextField
            name="repetirNovaSenha"
            label="Repetir nova senha"
            fullWidth
            required
            value={repetirNovaSenha}
            type="password"
            onChange={handleChangeRepetirNovaSenha}
            disabled={loading}
            helperText="Repita a NOVA senha"
          />

          <AdornedButton
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            className={classes.submit}
            disabled={loading || novaSenha.length < 6 || novaSenha !== repetirNovaSenha}>
            Ok
          </AdornedButton>
        </form>
      </>
    );
  };

  const sucessoResponsavel = () => {
    return (
      <Card className={localClasses.root}>
        <CardContent className={localClasses.content}>
          <img
            height="200"
            alt="logo"
            src={require('../../assets/imgs/mascote_busto_sereno.png')}
          />
          <Typography variant="h5" component="h2">
            Senha alterada com sucesso!
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Por favor, retorne ao aplicativo da escola no seu celular e faça login com a nova senha.
          </Typography>
        </CardContent>
      </Card>
    );
  };

  return (
    <main className={classes.main}>
      <CssBaseline />
      <Paper className={classes.paper}>{!sucesso ? form() : sucessoResponsavel()}</Paper>
    </main>
  );
};

export default withStyles(loginStyles)(withSnackbar(DefinirNovaSenha));
