import { dateHelper } from './';

/**
 * Converte um to em comunicado.
 */
const getComunicadoFromTO = (to) => {
  if (to !== undefined) {
    if (Array.isArray(to)) {
      return to.map(getComunicadoFromTO);
    } else {
      let comunicado = {
        uuid: to.uuid,
        titulo: to.titulo,
        mensagem: to.mensagem,
        destinatarios_todos: to.destinatarios_todos,
        envio_imediato: to.envio_imediato,
        data_disponivel: dateHelper.axiosDateRequestParser(to.data_disponivel),
        permite_resposta: to.permite_resposta,
        data_criacao: dateHelper.axiosDateRequestParser(to.data_criacao),
        funcionario_cadastro:
          to.funcionario_cadastro !== undefined
            ? {
                uuid: to.funcionario_cadastro.uuid,
                nome: to.funcionario_cadastro.nome
              }
            : undefined,
        setor_cadastro:
          to.setor_cadastro !== undefined
            ? {
                uuid: to.setor_cadastro.uuid,
                nome: to.setor_cadastro.nome
              }
            : undefined,
        destinatarios:
          to.destinatarios !== undefined
            ? to.destinatarios.map((dest) => ({
                responsavel:
                  dest.responsavel !== undefined
                    ? {
                        uuid: dest.responsavel.uuid,
                        nome: dest.responsavel.nome
                      }
                    : undefined,
                aluno:
                  dest.aluno !== undefined
                    ? {
                        uuid: dest.aluno.uuid,
                        nome: dest.aluno.nome
                      }
                    : undefined,
                data_recebimento: dateHelper.axiosDateRequestParser(dest.data_recebimento),
                data_leitura: dateHelper.axiosDateRequestParser(dest.data_leitura),
                data_ciente: dateHelper.axiosDateRequestParser(dest.data_ciente),
                data_envio_push: dateHelper.axiosDateRequestParser(dest.data_envio_push)
              }))
            : undefined,
        status:
          to.status !== undefined
            ? {
                id: to.status.id,
                descricao: to.status.descricao
              }
            : undefined,
        anexos:
          to.anexos !== undefined
            ? to.anexos.map((anexo) => ({
                id: anexo.id,
                nome: anexo.nome,
                mimetype: anexo.mimetype,
                data: anexo.data,
                uri: anexo.uri,
                tamanho: anexo.tamanho
              }))
            : undefined
      };
      return { ...comunicado };
    }
  } else {
    return undefined;
  }
};

/**
 * Converte um comunicado em to.
 */
const getTOFromComunicado = (comunicado) => {
  if (comunicado !== undefined) {
    if (Array.isArray(comunicado)) {
      return comunicado.map(getTOFromComunicado);
    } else {
      let to = {
        uuid: comunicado.uuid,
        titulo: comunicado.titulo,
        mensagem: comunicado.mensagem,
        destinatarios_todos: comunicado.destinatarios_todos,
        envio_imediato: comunicado.envio_imediato,
        qtd_anexos: comunicado.qtd_anexos,
        data_disponivel:
          comunicado.data_disponivel !== undefined
            ? dateHelper.converteDataHoraEmString(comunicado.data_disponivel)
            : undefined,
        permite_resposta: comunicado.permite_resposta,
        data_criacao:
          comunicado.data_criacao !== undefined
            ? dateHelper.converteDataHoraEmString(comunicado.data_criacao)
            : undefined,
        funcionario_cadastro:
          comunicado.funcionario_cadastro !== undefined
            ? {
                uuid: comunicado.funcionario_cadastro.uuid,
                nome: comunicado.funcionario_cadastro.nome
              }
            : undefined,
        setor_cadastro:
          comunicado.setor_cadastro !== undefined
            ? {
                uuid: comunicado.setor_cadastro.uuid,
                nome: comunicado.setor_cadastro.nome
              }
            : undefined,
        data_revisao:
          comunicado.data_revisao !== undefined
            ? dateHelper.converteDataHoraEmString(comunicado.data_revisao)
            : undefined,
        funcionario_revisao:
          comunicado.funcionario_revisao !== undefined
            ? {
                uuid: comunicado.funcionario_revisao.uuid,
                nome: comunicado.funcionario_revisao.nome
              }
            : undefined,
        setor_revisao:
          comunicado.setor_revisao !== undefined
            ? {
                uuid: comunicado.setor_revisao.uuid,
                nome: comunicado.setor_revisao.nome
              }
            : undefined,
        destinatarios:
          comunicado.destinatarios !== undefined
            ? comunicado.destinatarios.map((dest) => ({
                responsavel:
                  dest.responsavel !== undefined
                    ? {
                        uuid: dest.responsavel.uuid,
                        nome: dest.responsavel.nome
                      }
                    : undefined,
                aluno:
                  dest.aluno !== undefined
                    ? {
                        uuid: dest.aluno.uuid,
                        nome: dest.aluno.nome
                      }
                    : undefined,
                data_recebimento:
                  comunicado.data_recebimento !== undefined
                    ? dateHelper.converteDataHoraEmString(dest.data_recebimento)
                    : undefined,
                data_leitura:
                  dest.data_leitura !== undefined
                    ? dateHelper.converteDataHoraEmString(dest.data_leitura)
                    : undefined,
                data_ciente:
                  dest.data_ciente !== undefined
                    ? dateHelper.converteDataHoraEmString(dest.data_ciente)
                    : undefined,
                data_envio_push:
                  dest.data_envio_push !== undefined
                    ? dateHelper.converteDataHoraEmString(dest.data_envio_push)
                    : undefined
              }))
            : undefined,
        status:
          comunicado.status !== undefined
            ? {
                id: comunicado.status.id,
                descricao: comunicado.status.descricao
              }
            : undefined,
        anexos:
          comunicado.anexos !== undefined
            ? comunicado.anexos.map((anexo) => ({
                id: anexo.id,
                nome: anexo.nome,
                mimetype: anexo.mimetype,
                data: anexo.data,
                uri: anexo.uri,
                tamanho: anexo.tamanho
              }))
            : undefined
      };
      return { ...to };
    }
  } else {
    return undefined;
  }
};

const getTOFromConsultaPaginada = (consultaPaginada) => {
  if (consultaPaginada !== undefined) {
    return {
      page: consultaPaginada.pagina > 0 ? consultaPaginada.pagina - 1 : consultaPaginada.pagina,
      totalCount: consultaPaginada.total_itens,
      data: getTOFromComunicado(consultaPaginada.itens)
    };
  } else {
    return undefined;
  }
};

const getMensagemSemHtml = (html) => {
  return html
    ?.replace(/\<.*?\>/g, ' ')
    .replace(/\&.*?\;/g, ' ')
    .replace(/\s\s+/g, ' ')
    .trim();
};

export const comunicadoHelper = {
  getComunicadoFromTO,
  getTOFromComunicado,
  getTOFromConsultaPaginada,
  getMensagemSemHtml
};
