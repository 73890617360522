/* eslint-disable react/prop-types */
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import Clear from '@material-ui/icons/Clear';
import { withStyles } from '@material-ui/core/styles';
import { removeSnackbar } from '../_actions/notificacao.actions';

const styles = (theme) => ({
  button: {
    margin: theme.spacing(1)
  },
  input: {
    display: 'none'
  }
});

// Código de referência
// https://codesandbox.io/s/github/iamhosseindhv/notistack/tree/master/examples/redux-example

class Notifier extends React.Component {
  displayed = [];

  storeDisplayed = (id) => {
    this.displayed = [...this.displayed, id];
  };

  removeDisplayed = (id) => {
    this.displayed = this.displayed.filter((key) => id !== key);
  };

  componentDidUpdate() {
    const { notifications = [] } = this.props;

    notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        this.props.closeSnackbar(key);
        return;
      }

      // Do nothing if snackbar is already displayed
      if (this.displayed.includes(key)) return;

      // Se for uma lista de mensagens (erros de validação da API)
      if (Array.isArray(message)) {
        message.forEach((item) => {
          this.exibir(key, item, options);
        });
      } else {
        this.exibir(key, message, options);
      }
    });
  }

  exibir = (key, message, options) => {
    const { classes } = this.props;

    key = key + Math.random();

    // Display snackbar using notistack
    this.props.enqueueSnackbar(message, {
      ...options,
      action: (key) => (
        <IconButton
          className={classes.button}
          aria-label="Fechar"
          onClick={() => this.props.closeSnackbar(key)}>
          <Clear />
        </IconButton>
      ),
      onClose: (event, reason, key) => {
        if (options.onClose) {
          options.onClose(event, reason, key);
        }
      },
      onExited: (event, key) => {
        this.props.removeSnackbar(key);
        this.removeDisplayed(key);
      }
    });

    // Keep track of snackbars that we've displayed
    this.storeDisplayed(key);
  };

  render() {
    return null;
  }
}

const mapStateToProps = (store) => ({
  notifications: store.NotificacaoReducer.notifications
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ removeSnackbar }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withSnackbar(Notifier)));
