import format from "date-fns/format";
import ptBRLocale from "date-fns/locale/pt-BR";
import { Timestamp } from "firebase/firestore";

// const timezone = 'America/Recife';
// const timezone = 'America/Sao_Paulo';
// const timezone = 'UTC';

//'2019-12-01 11:00:00'
const parseDataHoraTextoEmDataObject = (dataTexto) => {
  if (dataTexto) {
    const [data, horario] = dataTexto.split(" ");
    const [ano, mes, dia] = data.split("-");
    const [hora, minuto, segundo] = horario.split(":");

    return new Date(ano, mes - 1, dia, hora, minuto, segundo);
  }
  return undefined;
};

//'01-12-2019 11:00:00'
const parseDataHoraDMYTextoEmDataObject = (dataTexto) => {
  const [data, horario] = dataTexto.split(" ");
  const [dia, mes, ano] = data.split("/");
  const [hora, minuto, segundo] = horario.split(":");

  return new Date(ano, mes - 1, dia, hora, minuto, segundo);
};

//'2019-12-01'
const parseDataTextoEmDataObject = (dataTexto) => {
  const [ano, mes, dia] = dataTexto.split("-");
  return new Date(ano, mes - 1, dia);
};

const parseDataTextoStringAPIFormat = (dataTexto) => {
  const [dia, mes, ano] = dataTexto.split("/");
  return ano + "-" + mes + "-" + dia;
};

const converteStringEmData = (dataTexto) => {
  return converteDataEmString(parseDataTextoEmDataObject(dataTexto));
};

const converteStringEmDataHora = (dataTexto) => {
  return converteDataHoraEmString(parseDataHoraTextoEmDataObject(dataTexto));
};

const converteDataEmString = (data) => {
  return data.toLocaleDateString("pt-BR");
};

const converteDataHoraEmString = (data) => {
  if (!(data instanceof Date)) {
    data = new Date(data);
  }

  return (
    data.toLocaleDateString("pt-BR") +
    " " +
    data.toLocaleTimeString("pt-BR", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    })
  );
};

const converteDataEmFirestoreTimestamp = (data) => {
  return Timestamp.fromDate(getDateWithCorrectTimezone(data));
};

const currentTimezoneOffset = process.env.REACT_APP_CURRENT_TIMEZONE_OFFSET;
const getDateWithCorrectTimezone = (date) => {
  var timezoneOffset = currentTimezoneOffset * 60 * 60000;
  var userOffset = date.getTimezoneOffset() * 60000; // [min*60000 = ms]
  return new Date(date.getTime() + timezoneOffset + userOffset);
};

const convertFirestoreTimestampToString = (timestamp, pattern) => {
  if (timestamp) {
    return converteDataEmStringPattern(timestamp.toDate(), pattern);
  } else {
    return "";
  }
};

const converteDataEmStringPattern = (data, pattern) => {
  return format(data, pattern, {
    locale: ptBRLocale,
  });
};

const formatDate = (date) => {
  if (date !== null) {
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();

    return [
      date.getFullYear(),
      (mm > 9 ? "" : "0") + mm,
      (dd > 9 ? "" : "0") + dd,
    ].join("-");
  }
};

const formatCompetencia = (date) => {
  const mes = ("00" + (date.getMonth() + 1)).slice(-2);
  const competencia = `${date.getFullYear()}${mes}`;
  return competencia;
};

const getLocalISOTime = (data) => {
  var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  var localISOTime = new Date(data - tzoffset).toISOString().slice(0, -1);

  return localISOTime;
};

/**
 * Utilizado no envio de datas ao backend.
 * @param {*} date
 */
const axiosDateRequestParser = (date) => {
  if (date) {
    if (date !== "" && !(date instanceof Date)) {
      date = parseDataHoraDMYTextoEmDataObject(date);
    }

    if (date instanceof Date) {
      const ano = date.getFullYear();
      const mes = ("0" + (parseInt(date.getMonth()) + 1)).slice(-2);
      const dia = ("0" + date.getDate()).slice(-2);
      const hora = ("0" + date.getHours()).slice(-2);
      const minutos = ("0" + date.getMinutes()).slice(-2);
      const segundos = ("0" + date.getSeconds()).slice(-2);
      const milisegundos = date.getMilliseconds();
      return (
        ano +
        "-" +
        mes +
        "-" +
        dia +
        "T" +
        hora +
        ":" +
        minutos +
        ":" +
        segundos +
        "." +
        milisegundos
      );
    } else {
      throw new Error("Objeto informado não é uma instância de Date.");
    }
  }
  return date;
};

export const dateHelper = {
  axiosDateRequestParser,
  converteDataEmString,
  converteDataHoraEmString,
  convertFirestoreTimestampToString,
  converteStringEmData,
  converteStringEmDataHora,
  converteDataEmFirestoreTimestamp,
  parseDataTextoEmDataObject,
  parseDataHoraTextoEmDataObject,
  parseDataTextoStringAPIFormat,
  parseDataHoraDMYTextoEmDataObject,
  formatCompetencia,
  formatDate,
  getLocalISOTime,
  converteDataEmStringPattern,
};
