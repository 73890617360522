import { httpHelper } from '../_helpers';

export const ingredienteService = {
  obterTodosIngredientes,
  obterTodosIngredientesPaginado,
  gravarIngrediente,
  removerIngrediente,
  alterarIngrediente,
  obterIngredientePorUuid,
  pesquisaIngredientesPaginado,
  obterTodosIngredientesUtilizados
};

function obterTodosIngredientes(status = true) {
  return httpHelper.get('/seguro/ingredientes/', { params: { status } });
}

function obterTodosIngredientesUtilizados(status = true) {
  return httpHelper.get('/seguro/ingredientes/uso_cardapio', {
    params: { status }
  });
}

function obterTodosIngredientesPaginado(status = true, page = 1, page_size = 10) {
  return httpHelper.get('/seguro/ingredientes/paginado', {
    params: { status, page, page_size }
  });
}

function pesquisaIngredientesPaginado(valor, status = true, page = 1, page_size = 10) {
  return httpHelper.get('/seguro/ingredientes/paginado/pesquisa/nome', {
    params: { valor, status, page, page_size }
  });
}

function gravarIngrediente(dados) {
  return httpHelper.post('/seguro/ingredientes/', { data: dados });
}

function obterIngredientePorUuid(uuid) {
  return httpHelper.get(`/seguro/ingredientes/${uuid}`);
}

function alterarIngrediente(dados) {
  return httpHelper.put(`/seguro/ingredientes/`, { data: dados });
}

async function removerIngrediente(uuid) {
  return httpHelper.del(`/seguro/ingredientes/${uuid}`);
}
