import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { authHelper } from '.';

const protocolo = process.env.REACT_APP_PROTOCOL;
const host = process.env.REACT_APP_API_SERVER;
const port = process.env.REACT_APP_API_PORT;
const version = process.env.REACT_APP_API_VERSION;

export const API_URL = protocolo + '://' + host + ':' + port + '/' + version;
axios.defaults.baseURL = API_URL;

const refreshToken = async () => {
  try {
    const response = await axios.get('/autenticacao/renovar', {
      headers: { Authorization: 'Bearer ' + authHelper.getRefreshToken() }
    });
    return response.data;
  } catch (error) {
    return _handleError(error);
  }
};

// Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest) => {
  refreshToken()
    .then((tokenRefreshResponse) => {
      authHelper.setAccessToken(tokenRefreshResponse.access_token);
      failedRequest.response.config.headers['Authorization'] =
        'Bearer ' + tokenRefreshResponse.access_token;
      return Promise.resolve();
    })
    .catch((error) => {
      console.log('error ao tentar renovar token:', error);
      authHelper.removeCredentials();
    });
};

// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(axios, refreshAuthLogic, { statusCodes: [440] });

const fetch = (url, options) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };

  if (authHelper.isAccessTokenValid()) {
    headers['Authorization'] = 'Bearer ' + authHelper.getAccessToken();
  }

  if (!url.startsWith('/')) {
    url = url.concat('/');
  }
  return (
    axios({
      url: url,
      headers,
      ...options
    })
      // .then(_checkStatus)
      .then((response) => response)
      .catch(_handleError)
  );
};

const _handleError = (error) => {
  if (error.response) {
    throw error;
  }
};

const get = (url, options) => {
  return fetch(url, { method: 'GET', ...options });
};

const post = (url, options) => {
  return fetch(url, { method: 'POST', ...options });
};

const put = (url, options) => {
  return fetch(url, { method: 'PUT', ...options });
};

const del = (url, options) => {
  return fetch(url, { method: 'DELETE', ...options });
};

export const httpHelper = {
  get,
  put,
  post,
  del
};
