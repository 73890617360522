export const FILE_SIZE = 160 * 1024;

export const IMAGE_SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

export const ACCEPT_MIME_TYPES = [
  { type: 'image/*', icon: 'file-image' },
  { type: 'text/*', icon: 'file-alt' },
  { type: 'video/*', icon: 'file-video' },
  {
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    icon: 'file-word'
  },
  { type: 'application/msword', icon: 'file-word' },
  { type: 'application/vnd.oasis.opendocument.text', icon: 'file-word' },
  {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    icon: 'file-excel'
  },
  { type: 'application/excel', icon: 'file-excel' },
  { type: 'application/vnd.ms-excel', icon: 'file-excel' },
  { type: 'application/msexcel', icon: 'file-excel' },
  {
    type: 'application/vnd.oasis.opendocument.spreadsheet',
    icon: 'file-excel'
  },
  {
    type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    icon: 'file-powerpoint'
  },
  { type: 'application/vnd.ms-powerpoint', icon: 'file-powerpoint' },
  {
    type: 'application/vnd.oasis.opendocument.presentation',
    icon: 'file-powerpoint'
  },
  { type: 'application/pdf', icon: 'file-pdf' },
  { type: 'application/zip', icon: 'file-archive' },
  { type: 'application/x-rar-compressed', icon: 'file-archive' },
  { type: 'application/x-7z-compressed', icon: 'file-archive' },
  { type: 'application/octet-stream', icon: 'file-archive' },
  { type: 'application/x-zip-compressed', icon: 'file-archive' },
  { type: 'multipart/x-zip', icon: 'file-archive' }
];

export const tiposSanguineos = [
  { value: 'A+', label: 'A+' },
  { value: 'A-', label: 'A-' },
  { value: 'B+', label: 'B+' },
  { value: 'B-', label: 'B-' },
  { value: 'AB+', label: 'AB+' },
  { value: 'AB', label: 'AB' },
  { value: 'O+', label: 'O+' },
  { value: 'O-', label: 'O-' }
];

export const alergias = [
  { value: 0, label: 'abacate' },
  { value: 1, label: 'avelã' },
  { value: 2, label: 'banana' },
  { value: 3, label: 'batata' },
  { value: 4, label: 'camarão' },
  { value: 5, label: 'carne de frango' },
  { value: 6, label: 'carne de porco' },
  { value: 7, label: 'carne de vaca' },
  { value: 8, label: 'castanhas' },
  { value: 9, label: 'centeio' },
  { value: 10, label: 'cevada' },
  { value: 11, label: 'chocolate' },
  { value: 12, label: 'clara de ovo' },
  { value: 13, label: 'crustáceos' },
  { value: 14, label: 'frutas secas' },
  { value: 15, label: 'frutos do mar' },
  { value: 16, label: 'gema de ovo' },
  { value: 17, label: 'gergelim' },
  { value: 18, label: 'glúten' },
  { value: 19, label: 'grãos' },
  { value: 20, label: 'kiwi' },
  { value: 21, label: 'lagosta' },
  { value: 22, label: 'leite de vaca' },
  { value: 23, label: 'mandioca' },
  { value: 24, label: 'maracujá' },
  { value: 25, label: 'ovo' },
  { value: 26, label: 'peixe' },
  { value: 27, label: 'pimentão' },
  { value: 28, label: 'pêssego' },
  { value: 29, label: 'pólen' },
  { value: 30, label: 'soja' },
  { value: 31, label: 'tomate' },
  { value: 32, label: 'trigo' }
];

export const perfisFuncionario = {
  FUNCIONARIO: 1,
  PROFESSOR: 2
};

export const tabsChat = {
  TAB_MENSAGENS_SETOR: 'mensagens_setor',
  TAB_MENSAGENS_INDIVIDUAL: 'mensagens_individual',
  TAB_COMUNICADOS: 'comunicados'
};
