const convertTurmaEmTO = (turma) => {
  let to = {
    grauEscolar: {
      label: turma.grau_escolar.descricao,
      value: turma.grau_escolar.id
    },
    periodo: {
      label: turma.periodo.descricao,
      value: turma.periodo.id
    },
    nome: turma.nome,
    status: turma.status,
    ano: turma.ano,
    professores: [],
    alunos: [],
    disciplinas: []
  };

  if (turma.professores) {
    turma.professores.forEach((prof) => {
      to.professores.push({
        nome: prof.nome
      });
    });
  }

  if (turma.alunos) {
    turma.alunos.forEach((aluno) => {
      to.alunos.push({
        label: aluno.nome,
        value: aluno.uuid
      });
    });
  }

  if (turma.disciplinas) {
    turma.disciplinas.forEach((disciplina) => {
      to.disciplinas.push({
        disciplina: {
          uuid: disciplina.disciplina.uuid,
          nome: disciplina.disciplina.nome
        },
        funcionarios: disciplina.funcionarios.map((func) => ({
          value: func.uuid,
          label: func.nome
        }))
      });
    });
  }

  return { ...to };
};

const convertTOEmTurma = (to) => {
  let turma = {
    nome: to.nome,
    ano: to.ano,
    status: to.status,
    periodo: {
      id: to.periodo.value
    },
    grau_escolar: {
      id: to.grauEscolar.value
    },
    alunos: [],
    disciplinas: []
  };

  if (to.alunos) {
    to.alunos.forEach((aluno) => {
      turma.alunos.push({
        uuid: aluno.value,
        nome: aluno.label
      });
    });
  }

  if (to.disciplinas) {
    to.disciplinas.forEach((disciplina) => {
      turma.disciplinas.push({
        disciplina: { uuid: disciplina.disciplina.uuid },
        funcionarios: disciplina.funcionarios.map((func) => ({
          uuid: func.value
        }))
      });
    });
  }

  return { ...turma };
};

export const turmaHelper = {
  convertTOEmTurma,
  convertTurmaEmTO
};
