import {
  gravarDisciplina,
  obterListaDisciplinas,
  removerDisciplina,
  obterDisciplina,
  alterarDisciplina
} from '../_actions';

const initialState = { disciplinas: [], disciplina: {} };

export const disciplinaReducer = (state = initialState, action) => {
  switch (action.type) {
    case String(gravarDisciplina.fulfilled):
      return { ...state, disciplina: action.payload.data };
    case String(obterListaDisciplinas.fulfilled):
      return { ...state, disciplinas: action.payload.data };
    case String(obterDisciplina.fulfilled):
      return { ...state, disciplina: action.payload.data };
    case String(removerDisciplina.fulfilled):
      return { ...state, disciplinas: [] };
    case String(alterarDisciplina.fulfilled):
      return { ...state, disciplina: action.payload.data };
    default:
      return state;
  }
};
