import React, { useEffect } from 'react';
import queryString from 'query-string';

import { Button } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';

import { loginStyles } from '../../components/styles/LoginStyles';

import Rotas from '../../_constants/rotas';

const ConfirmacaoVerificacaoConta = (props) => {
  const { classes } = props;

  useEffect(() => {
    // Caso o modo enviado na url não seja de resetar a senha, redirecionar para a página de login.
    if (
      queryString.parse(props.location.search, { ignoreQueryPrefix: true }).mode !== 'verifyEmail'
    ) {
      props.history.push(Rotas.autenticacao.login);
    }
  }, []);

  return (
    <main className={classes.main}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <img
          height="80"
          alt="logo"
          src={require('../../assets/imgs/ludica_logo_horizontalxhdpi_2x.png')}
        />

        <span style={{ fontWeight: '600' }}>O e-mail da sua conta foi verificado. Obrigado!</span>

        <Button
          variant="contained"
          color="primary"
          fullWidth
          className={classes.submit}
          onClick={() => props.history.push(Rotas.autenticacao.login)}>
          Ok
        </Button>
      </Paper>
    </main>
  );
};

export default withStyles(loginStyles)(ConfirmacaoVerificacaoConta);
