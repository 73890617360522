import { createAsyncAction } from 'redux-promise-middleware-actions';
import { disciplinaService } from '../_services';

export const gravarDisciplina = createAsyncAction('DISCIPLINA_SALVAR', async (disciplina) => {
  return await disciplinaService.gravarDisciplina(disciplina);
});

export const obterListaDisciplinas = createAsyncAction('DISCIPLINA_OBTER_LISTA', async () => {
  return await disciplinaService.obterTodasDisciplinas();
});

export const obterDisciplina = createAsyncAction('DISCIPLINA_OBTER', async (uuid) => {
  return await disciplinaService.obterDisciplina(uuid);
});

export const removerDisciplina = createAsyncAction('DISCIPLINA_REMOVER', async (uuid) => {
  return await disciplinaService.removerDisciplina(uuid);
});

export const alterarDisciplina = createAsyncAction('DISCIPLINA_ALTERAR', async (disciplina) => {
  return await disciplinaService.alterarDisciplina(disciplina);
});
