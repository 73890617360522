/**
 * Rotas do sistema.
 */
const Rotas = {
  autenticacao: {
    login: "/",
    esqueci_senha: "/esqueci-senha",
    definir_nova_senha: "/definir-nova-senha",
    verificacao_conta: "/verificar-conta",
  },
  ausencia: {
    visualizar: "/visualizar-ausencia/:_uuid",
    listar: "/lista-ausencias",
  },
  setor: {
    cadastrar: "/cadastro-setor",
    alterar: "/alterar-setor/:_uuid",
    listar: "/lista-setores",
  },
  home: "/home",
  funcionario: {
    cadastrar: "/cadastro-funcionario",
    alterar: "/alterar-funcionario/:_uuid",
    listar: "/lista-funcionarios",
  },
  professor: {
    cadastrar: "/cadastro-professor",
    alterar: "/alterar-professor/:_uuid",
    listar: "/lista-professores",
  },
  responsavel: {
    cadastrar: "/cadastro-responsavel",
    alterar: "/alterar-responsavel/:_uuid",
    listar: "/lista-responsaveis",
  },
  aluno: {
    cadastrar: "/cadastro-aluno",
    alterar: "/alterar-aluno/:_uuid",
    listar: "/lista-alunos",
    desabilitar: "/desabilitar-alunos",
  },
  turma: {
    cadastrar: "/cadastro-turma",
    alterar: "/alterar-turma/:_uuid",
    listar: "/lista-turmas",
    migracao: "/migrar-turma",
  },
  disciplina: {
    cadastrar: "/cadastro-disciplina",
    alterar: "/alterar-disciplina/:_uuid",
    listar: "/lista-disciplinas",
  },
  evento: {
    cadastrar: "/cadastro-evento",
    alterar: "/alterar-evento/:_uuid",
    listar: "/lista-eventos",
  },
  cardapio: {
    cadastrar: "/cadastro-cardapio",
    alterar: "/alterar-cardapio/:_uuid",
    listar: "/lista-cardapios",
    planejar: "/planejamento-cardapios",
  },
  dashboard: {
    mensagens: "/dashboard-mensagens",
    mensagens_chart: "/dashboard-mensagens-chartj",
    diario: "/dashboard-diario",
    diario_chart: "/dashboard-diario-chartj",
  },
  caixa: {
    unica: "/mensagens",
    unica_from_badge: "/mensagens/:_tipo/:_id",
    envio: "/envio-mensagem",
    revisao: "/revisar-mensagens",
    chats: "/chats",
    comunicados: "/comunicados",
    lista: "/comunicadosv2/listar",
    detalhamento: "/comunicadosv2/detalhar/:_id",
  },
  comunicados: {
    lista: "/poc-comunicados/listar",
    detalhamento: "/poc-comunicados/detalhar/:_uuid",
  },
  album: {
    listar: "/lista-album",
    galeria: "/galeria/:_idAlbum",
  },
  rotina: {
    cadastrar: "/cadastro-rotina",
    alterar: "/rotina-diaria/(visualizar|alterar)",
    listar: "/lista-rotina",
  },
  ingredientes: {
    manter: "/ingredientes",
  },
  informativo: {
    cadastrar: "/cadastro-informativo",
    alterar: "/alterar-informativo/:_uuid",
    listar: "/lista-informativos",
  },
  chatrooms: {
    professores: "/chats-professores",
  },
  configuracoes: {
    geral: "/configuracoes/geral",
  },
  comunicacao: "/comunicacao",
};

export default Rotas;
