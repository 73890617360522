import React, { useState } from 'react';

import { Link as RouterLink } from 'react-router-dom';
import { withSnackbar } from 'notistack';

import Rotas from '../../_constants/rotas';

import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import CssBaseline from '@material-ui/core/CssBaseline';
import withStyles from '@material-ui/core/styles/withStyles';

import { validaEmail } from '../../_validators/email.validator';

import { loginStyles } from '../../components/styles/LoginStyles';
import AdornedButton from '../../components/AdornedButton';

import { esqueciSenhaService } from '../../_services';
import mapMensagensFirebase from '../../_constants/firebase.mensagens';

/**
 * Tela de recuperação de senha do usuário.
 * @param {*} props
 * @returns
 */
const EsqueciSenha = (props) => {
  const { classes } = props;
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  /**
   * Atualiza o valor do e-mail.
   * @param {*} e
   */
  const handleChangeEmail = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  /**
   * Envio do formulário.
   * @param {*} e
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    esqueciSenhaService
      .recuperarSenha(email)
      .then((resp) => {
        props.enqueueSnackbar('E-mail enviado com sucesso!', {
          variant: 'success'
        });
        props.history.push(Rotas.autenticacao.login);
      })
      .catch((error) => {
        props.enqueueSnackbar(mapMensagensFirebase.get(error?.code), {
          variant: 'error'
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <main className={classes.main}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <img
          height="80"
          alt="logo"
          src={require('../../assets/imgs/ludica_logo_horizontalxhdpi_2x.png')}
        />
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            name="email"
            label="E-mail"
            fullWidth
            required
            value={email}
            onChange={handleChangeEmail}
            autoFocus
            disabled={loading}
            helperText="Informe seu e-mail cadastrado"
          />
          <AdornedButton
            id="btn-esqueci-senha-ok"
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            className={classes.submit}
            disabled={!validaEmail(email) || loading}>
            Ok
          </AdornedButton>
          <br />
          <br />
          <Link
            id="link-esqueci-senha-retornar"
            component={RouterLink}
            to={Rotas.autenticacao.login}>
            Retornar
          </Link>
        </form>
      </Paper>
    </main>
  );
};

export default withStyles(loginStyles)(withSnackbar(EsqueciSenha));
