import React from "react";
import { Link } from "react-router-dom";

import Rotas from "../../_constants/rotas";

import HomeIcon from "@material-ui/icons/Home";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AddIcon from "@material-ui/icons/Add";
import ListIcon from "@material-ui/icons/List";
import ChildCareIcon from "@material-ui/icons/ChildCare";
import Message from "@material-ui/icons/Message";
import TodayIcon from "@material-ui/icons/Today";
import HealingIcon from "@material-ui/icons/Healing";
import WorkIcon from "@material-ui/icons/Work";
import ClassIcon from "@material-ui/icons/Class";
import BookIcon from "@material-ui/icons/Book";
import LockOpen from "@material-ui/icons/LockOpen";
import Restaurant from "@material-ui/icons/Restaurant";
import List from "@material-ui/core/List";
import Tune from "@material-ui/icons/Tune";
import School from "@material-ui/icons/School";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import SettingsIcon from "@material-ui/icons/Settings";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Forum from "@material-ui/icons/Forum";

import ItemMenu from "./ItemMenu";
import SubItemMenu from "./SubItemMenu";

import { authHelper } from "../../_helpers";
import Ballot from "@material-ui/icons/Ballot";
import ImportContacts from "@material-ui/icons/ImportContacts";
import ImportExport from "@material-ui/icons/ImportExport";
import { useMainContext } from "../../provider";

const firstLevelStyle = {
  marginTop: 0,
};

const secondLevelStyle = {
  marginTop: 0,
  padding: 5,
  paddingLeft: "30px",
};

export function MainListItems() {
  const permissoes = authHelper.getPermissoesSistema();
  const { cleanData } = useMainContext();

  return (
    <div>
      <SubItemMenu
        id="menu-pagina-principal"
        component={Link}
        to={Rotas.home}
        titulo="Página inicial"
        icon={<HomeIcon />}
        style={firstLevelStyle}
      />
      <ItemMenu
        id="menu-administracao"
        titulo="Administração"
        icon={<Ballot />}
        style={firstLevelStyle}
      >
        <List component="div">
          <ItemMenu
            id="menu-setores"
            titulo="Setores"
            icon={<BookIcon />}
            style={secondLevelStyle}
          >
            <List component="div" disablePadding>
              <SubItemMenu
                id="item-menu-setores-listar"
                nested
                component={Link}
                to={Rotas.setor.listar}
                titulo="Lista"
                icon={<ListIcon />}
              />
              <SubItemMenu
                id="item-menu-setores-cadastrar"
                nested
                component={Link}
                to={Rotas.setor.cadastrar}
                titulo="Cadastro"
                render={permissoes.setores.cadastrar}
                icon={<AddIcon />}
              />
            </List>
          </ItemMenu>
          <SubItemMenu
            titulo="Configurações"
            icon={<SettingsIcon />}
            component={Link}
            to={Rotas.configuracoes.geral}
            render={permissoes.configuracoes}
            style={secondLevelStyle}
          />
        </List>
      </ItemMenu>

      <ItemMenu
        id="menu_cadastros"
        titulo="Cadastros"
        icon={<SupervisorAccountIcon />}
        style={firstLevelStyle}
      >
        <List component="div">
          <ItemMenu
            id="menu_funcionarios"
            titulo="Funcionários"
            icon={<WorkIcon />}
            style={secondLevelStyle}
          >
            <List component="div" disablePadding>
              <SubItemMenu
                id="item_menu_funcionarios_listar"
                nested
                component={Link}
                to={Rotas.funcionario.listar}
                titulo="Lista"
                icon={<ListIcon />}
              />
              <SubItemMenu
                id="item_menu_funcionarios_cadastrar"
                nested
                component={Link}
                to={Rotas.funcionario.cadastrar}
                titulo="Cadastro"
                icon={<AddIcon />}
                render={permissoes.funcionarios.cadastrar}
              />
            </List>
          </ItemMenu>
          <ItemMenu
            id="menu_professores"
            titulo="Professores"
            icon={<School />}
            style={secondLevelStyle}
          >
            <List component="div" disablePadding>
              <SubItemMenu
                id="item_menu_professores_listar"
                nested
                component={Link}
                to={Rotas.professor.listar}
                titulo="Lista"
                icon={<ListIcon />}
              />
              <SubItemMenu
                id="item_menu_professores_cadastrar"
                nested
                component={Link}
                to={Rotas.professor.cadastrar}
                titulo="Cadastro"
                render={permissoes.funcionarios.cadastrar}
                icon={<AddIcon />}
              />
            </List>
          </ItemMenu>
          <ItemMenu
            id="menu_responsaveis"
            titulo="Responsáveis"
            icon={<SupervisorAccountIcon />}
            style={secondLevelStyle}
          >
            <List component="div" disablePadding>
              <SubItemMenu
                id="item_menu_responsaveis_listar"
                nested
                component={Link}
                to={Rotas.responsavel.listar}
                titulo="Lista"
                icon={<ListIcon />}
              />
              <SubItemMenu
                id="item_menu_responsaveis_cadastrar"
                nested
                component={Link}
                to={Rotas.responsavel.cadastrar}
                titulo="Cadastro"
                icon={<AddIcon />}
                render={permissoes.responsaveis.cadastrar}
              />
            </List>
          </ItemMenu>
          <ItemMenu
            id="menu_alunos"
            titulo="Alunos"
            icon={<ChildCareIcon />}
            style={secondLevelStyle}
          >
            <List component="div" disablePadding>
              <SubItemMenu
                id="item_menu_alunos_listar"
                nested
                component={Link}
                to={Rotas.aluno.listar}
                titulo="Lista"
                icon={<ListIcon />}
              />
              <SubItemMenu
                id="item_menu_alunos_cadastrar"
                nested
                component={Link}
                to={Rotas.aluno.cadastrar}
                titulo="Cadastro"
                icon={<AddIcon />}
                render={permissoes.alunos.cadastrar}
              />
              <SubItemMenu
                id="item_menu_alunos_bloqueio"
                render={permissoes.alunos.bloquear}
                nested
                component={Link}
                to={Rotas.aluno.desabilitar}
                titulo="Bloqueio"
                icon={<PersonAddDisabledIcon />}
              />
            </List>
          </ItemMenu>
          <ItemMenu
            id="menu_turmas"
            titulo="Turmas"
            icon={<ClassIcon />}
            style={secondLevelStyle}
          >
            <List component="div" disablePadding>
              <SubItemMenu
                id="item_menu_turmas_listar"
                nested
                component={Link}
                to={Rotas.turma.listar}
                titulo="Lista"
                icon={<ListIcon />}
              />
              <SubItemMenu
                id="item_menu_turmas_cadastrar"
                nested
                component={Link}
                to={Rotas.turma.cadastrar}
                titulo="Cadastro"
                icon={<AddIcon />}
                render={permissoes.turmas.cadastrar}
              />
              <SubItemMenu
                id="item_menu_turmas_importar"
                nested
                component={Link}
                to={Rotas.turma.migracao}
                titulo="Importar"
                icon={<ImportExport />}
                render={permissoes.turmas.cadastrar}
              />
            </List>
          </ItemMenu>
          <ItemMenu
            id="menu_disciplinas"
            titulo="Disciplinas"
            icon={<ImportContacts />}
            style={secondLevelStyle}
          >
            <List component="div" disablePadding>
              <SubItemMenu
                id="item_menu_disciplinas_listar"
                nested
                component={Link}
                to={Rotas.disciplina.listar}
                titulo="Lista"
                icon={<ListIcon />}
              />
              <SubItemMenu
                id="item_menu_disciplinas_cadastrar"
                nested
                component={Link}
                to={Rotas.disciplina.cadastrar}
                titulo="Cadastro"
                icon={<AddIcon />}
                render={permissoes.disciplinas.cadastrar}
              />
            </List>
          </ItemMenu>
        </List>
      </ItemMenu>

      <ItemMenu
        id="menu_agenda"
        titulo="Agenda"
        icon={<Ballot />}
        style={firstLevelStyle}
      >
        <List component="div">
          <ItemMenu
            id="menu_rotina"
            titulo="Rotina Diária"
            icon={<Tune />}
            render={permissoes.rotinas}
            style={secondLevelStyle}
          >
            <List component="div" disablePadding>
              <SubItemMenu
                id="item_menu_rotina_listar"
                nested
                component={Link}
                to={Rotas.rotina.listar}
                titulo="Listar"
                icon={<ListIcon />}
              />
              <SubItemMenu
                id="item_menu_rotina_cadastrar"
                nested
                component={Link}
                to={Rotas.rotina.cadastrar}
                titulo="Cadastro"
                icon={<AddIcon />}
              />
            </List>
          </ItemMenu>
          <ItemMenu
            id="menu_cardapio"
            titulo="Cardápio"
            icon={<Restaurant />}
            style={secondLevelStyle}
          >
            <List component="div" disablePadding>
              <SubItemMenu
                id="item_menu_cardapio_listar"
                nested
                component={Link}
                to={Rotas.cardapio.listar}
                titulo="Listar"
                icon={<ListIcon />}
              />
              <SubItemMenu
                id="item_menu_cardapio_cadastrar"
                nested
                component={Link}
                to={Rotas.cardapio.cadastrar}
                titulo="Cadastro"
                icon={<AddIcon />}
                render={permissoes.cardapios}
              />
              <SubItemMenu
                id="item_menu_cardapio_planejar"
                nested
                component={Link}
                to={Rotas.cardapio.planejar}
                titulo="Planejamento"
                icon={<TodayIcon />}
                render={permissoes.cardapios}
              />
              <SubItemMenu
                id="item_menu_cardapio_ingredientes"
                nested
                component={Link}
                to={Rotas.ingredientes.manter}
                titulo="Ingredientes"
                icon={<FontAwesomeIcon icon="carrot" />}
              />
            </List>
          </ItemMenu>
          <ItemMenu
            id="menu_ausencias"
            titulo="Ausências"
            icon={<HealingIcon />}
            style={secondLevelStyle}
            render={permissoes.ausencias}
          >
            <List component="div" disablePadding>
              <SubItemMenu
                id="item_menu_ausencias_listar"
                nested
                component={Link}
                to={Rotas.ausencia.listar}
                titulo="Lista"
                icon={<ListIcon />}
              />
            </List>
          </ItemMenu>
          <ItemMenu
            id="menu_eventos"
            titulo="Eventos/Datas"
            icon={<TodayIcon />}
            style={secondLevelStyle}
          >
            <List component="div" disablePadding>
              <SubItemMenu
                id="item_menu_evento_listar"
                nested
                component={Link}
                to={Rotas.evento.listar}
                titulo="Lista"
                icon={<ListIcon />}
              />
              <SubItemMenu
                id="item_menu_evento_cadastrar"
                nested
                component={Link}
                to={Rotas.evento.cadastrar}
                titulo="Cadastro"
                icon={<AddIcon />}
                render={permissoes.eventos.cadastrar}
              />
            </List>
          </ItemMenu>
          <ItemMenu
            id="menu_galeria"
            titulo="Galeria"
            icon={<PhotoCamera />}
            style={secondLevelStyle}
            render={permissoes.galerias}
          >
            <List component="div" disablePadding>
              <SubItemMenu
                id="item_menu_galeria_albuns"
                nested
                component={Link}
                to={Rotas.album.listar}
                titulo="Álbuns"
                icon={<ListIcon />}
              />
            </List>
          </ItemMenu>
        </List>
      </ItemMenu>
      <ItemMenu
        id="menu_correio"
        titulo="Correio"
        icon={<Message />}
        style={firstLevelStyle}
      >
        <SubItemMenu
          nested
          id="mensagens"
          render={
            permissoes.mensagens.enviar_mensagens_privado ||
            permissoes.mensagens.visualizar_mensagens_privado ||
            permissoes.mensagens.enviar_mensagens_setor ||
            permissoes.comunicados.enviar_responder
          }
          component={Link}
          to={Rotas.comunicacao}
          titulo="Mensagens"
          icon={<Forum />}
          style={secondLevelStyle}
        />
        <List component="div" disablePadding>
          <SubItemMenu
            nested
            component={Link}
            to={Rotas.caixa.lista}
            titulo="Comunicados"
            icon={<FontAwesomeIcon icon="inbox" style={{ fontSize: "1em" }} />}
            style={secondLevelStyle}
            onClick={cleanData}
          />
          {/* <SubItemMenu
            nested
            component={Link}
            to={Rotas.comunicados.lista}
            titulo="Comunicados 2.0"
            icon={
              <FontAwesomeIcon icon="mail-bulk" style={{ fontSize: "1em" }} />
            }
            style={secondLevelStyle}
          /> */}
          <SubItemMenu
            render={permissoes.comunicados.enviar_responder}
            nested
            component={Link}
            to={Rotas.caixa.envio}
            titulo="Enviar comunicados"
            icon={<AddIcon />}
            style={secondLevelStyle}
          />
          <SubItemMenu
            render={permissoes.comunicados.revisar}
            nested
            component={Link}
            to={Rotas.caixa.revisao}
            titulo="Revisar comunicados"
            icon={<LockOpen />}
            style={secondLevelStyle}
          />
        </List>
      </ItemMenu>
    </div>
  );
}
