const convertDisciplinaEmTO = (disciplina) => {
  let to = {
    nome: disciplina.nome
  };
  return { ...to };
};

export const disciplinaHelper = {
  convertDisciplinaEmTO
};
