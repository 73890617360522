/**
 * Descrição
 * ----------------------------------
 *    Componente que renderiza o sino com a quantidade de mensagens de chats não lidas por setor, comunicados e/ou privadas.
 */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";

import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import { authHelper } from "../../_helpers";
import { tabsChat } from "../../components/Constantes";
import Rotas from "../../_constants/rotas";
import { useChatsMensagensNaoLidas } from "../../_hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 230,
    backgroundColor: theme.palette.background.paper,
    paddingBottom: 0,
  },
  listItem: {
    paddingTop: 8,
    paddingBottom: 8,
    borderBottom: "1px solid #c8ced3",
  },
  listSubHeader: {
    background: "#e4e7ea",
    borderBottom: "1px solid #c8ced3",
    display: "block",
    marginBottom: 0,
    fontSize: ".76562rem",
    color: "#73818f",
    whiteSpace: "nowrap",
    fontWeight: "bolder",
    padding: "10px",
  },
}));

export const BadgeNotificacoes = (props) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const stateMsgNaoLidasChats = useChatsMensagensNaoLidas();
  const [permissoes, setPermissoes] = useState({});
  const isProfessor = authHelper.getClaimsUsuarioLogado().perfil.id === 2;

  useEffect(() => {
    try {
      setPermissoes(authHelper.getPermissoesSistema());
    } catch (Error) {
      props.history.push(Rotas.autenticacao.login);
    }
  }, [props.history]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "simple-popover" : undefined;

  const redirecionaParaMensagens = (tab) => {
    props.history.push(`/comunicacao?tab=${tab}`);
    handleClose();
  };

  return (
    <>
      <IconButton
        id="sino-notificacoes"
        color="inherit"
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
      >
        <Badge
          overlap="rectangular"
          badgeContent={
            (permissoes.comunicados?.enviar_responder && !isProfessor
              ? stateMsgNaoLidasChats.comunicados.qtdMsgNaoLidas
              : 0) +
            (permissoes.mensagens?.enviar_mensagens_privado
              ? stateMsgNaoLidasChats.professores.qtdMsgNaoLidas
              : 0) +
            (permissoes.mensagens?.enviar_mensagens_setor
              ? stateMsgNaoLidasChats.setor.qtdMsgNaoLidas
              : 0)
          }
          color="secondary"
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List
          id="lst-qtd-chats"
          component="nav"
          className={classes.root}
          aria-labelledby="lista-mensagens"
          style={{
            paddingTop: "0px",
          }}
        >
          {permissoes.comunicados?.enviar_responder && !isProfessor && (
            <ListItem
              id="chats-comunicados"
              alignItems="flex-start"
              button
              className={classes.listSubHeader}
              onClick={() => redirecionaParaMensagens(tabsChat.TAB_COMUNICADOS)}
            >
              {`${stateMsgNaoLidasChats.comunicados.qtdMsgNaoLidas} chat(s) de comunicados`}
            </ListItem>
          )}
          {permissoes.mensagens?.enviar_mensagens_setor && (
            <ListItem
              id="chats-setor"
              alignItems="flex-start"
              button
              className={classes.listSubHeader}
              onClick={() =>
                redirecionaParaMensagens(tabsChat.TAB_MENSAGENS_SETOR)
              }
            >
              {`${stateMsgNaoLidasChats.setor.qtdMsgNaoLidas} chat(s) de setor`}
            </ListItem>
          )}
          {permissoes.mensagens?.enviar_mensagens_privado && (
            <ListItem
              id="chats-privado"
              alignItems="flex-start"
              button
              className={classes.listSubHeader}
              onClick={() =>
                redirecionaParaMensagens(tabsChat.TAB_MENSAGENS_INDIVIDUAL)
              }
            >
              {`${stateMsgNaoLidasChats.professores.qtdMsgNaoLidas} chat(s) individuais`}
            </ListItem>
          )}
        </List>
      </Popover>
    </>
  );
};

export default withRouter(BadgeNotificacoes);
