import { createAsyncAction } from 'redux-promise-middleware-actions';
import { responsavelService } from '../_services';

export const salvarResponsavel = createAsyncAction('RESPONSAVEL_SALVAR', async (responsavel) => {
  return await responsavelService.gravarResponsavel(responsavel);
});

export const obterListaResponsaveis = createAsyncAction('RESPONSAVEL_OBTER_LISTA', async () => {
  return await responsavelService.obterTodosResponsaveis();
});

export const removerResponsavel = createAsyncAction('RESPONSAVEL_REMOVER', async (uuid) => {
  return await responsavelService.removerResponsavel(uuid);
});

export const obterResponsavel = createAsyncAction('RESPONSAVEL_OBTER', async (uuid) => {
  return await responsavelService.obterResponsavelPorUuid(uuid);
});

export const atualizarResponsavel = createAsyncAction(
  'RESPONSAVEL_ATUALIZAR',
  async (responsavel) => {
    return await responsavelService.alterarResponsavel(responsavel);
  }
);
