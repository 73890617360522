import { createAsyncAction } from 'redux-promise-middleware-actions';
import { turmaService } from '../_services';

export const salvarTurma = createAsyncAction('TURMA_SALVAR', async (turma) => {
  return await turmaService.gravarTurma(turma);
});

export const obterListaTurmas = createAsyncAction('TURMA_OBTER_LISTA', async () => {
  return await turmaService.obterTodasTurmas();
});

export const obterListaTurmasComAluno = createAsyncAction(
  'TURMA_OBTER_LISTA_CONTEM_ALUNOS',
  async () => {
    return await turmaService.obterTodasTurmas(true, true);
  }
);

//TODO: temporario
export const obterListaAlunosPorTurmas = createAsyncAction(
  'OBTER_LISTA_ALUNOS_POR_TURMA',
  async () => {
    const turmas = await turmaService.obterTodasTurmas();
    let alunos = [];
    turmas.forEach((turma) => {
      turma.alunos.forEach((aluno) => {
        alunos.push({
          uuid_turma: turma.uuid,
          nome_turma: turma.nome,
          ...aluno
        });
      });
    });
    return alunos;
  }
);

export const obterTurma = createAsyncAction('TURMA_OBTER', async (uuid) => {
  return await turmaService.obterTurmaPorUuid(uuid);
});

export const obterTurmasPorAno = createAsyncAction('TURMA_OBTER_POR_ANO', async (ano) => {
  return await turmaService.obterTurmasPorAno(ano);
});

export const obterTurmasPorNome = createAsyncAction('TURMA_OBTER_POR_NOME', async (nome) => {
  return await turmaService.obterTurmasPorNome(nome);
});

export const atualizarTurma = createAsyncAction('TURMA_ATUALIZAR', async (turma) => {
  return await turmaService.alterarTurma(turma);
});

export const removerTurma = createAsyncAction('TURMA_REMOVER', async (uuid) => {
  return await turmaService.removerTurma(uuid);
});

export const migrarTurma = createAsyncAction(
  'TURMA_MIGRAR',
  async (anoLetivoDestino, disciplinasEProfessores, dados) => {
    return await turmaService.migrarTurma(anoLetivoDestino, disciplinasEProfessores, dados);
  }
);

export const obterAnosTurmas = createAsyncAction('TURMA_OBTER_ANOS', async () => {
  return await turmaService.obterAnosTurmas();
});

global._migrarTurma = migrarTurma;
