import React from "react";
import { withRouter } from "react-router";
import Paper from "@material-ui/core/Paper";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentWillUpdate(nextProps) {
    if (this.props.location !== nextProps.location) {
      this.clearError();
    }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.log(error);
  }

  clearError() {
    this.setState({ hasError: false });
  }

  render() {
    if (this.state.hasError) {
      // if (true) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Paper elevation={2}>
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: "",
                padding: "64px",
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <div>
                    <div
                      style={{
                        border: "2px solid rgb(208 55 55)",
                        color: "rgb(208 55 55)",
                        width: "70px",
                        height: "70px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "999px",
                        fontSize: "50px",
                        fontWeight: "200",
                      }}
                    >
                      !
                    </div>
                  </div>
                  <div
                    style={{
                      paddingLeft: "20px",
                      fontSize: "3rem",
                      fontWeight: "200",
                    }}
                  >
                    Algo deu errado...
                  </div>
                </div>
                <div style={{ fontSize: "1.4rem", fontWeight: "300" }}>
                  Estamos trabalhando para corrigir o problema
                </div>
                <div
                  style={{
                    cursor: "pointer",
                    alignSelf: "center",
                    backgroundColor: "rgb(208 55 55)",
                    lineHeight: "40px",
                    marginTop: "40px",
                    textAlign: "center",
                    color: "white",
                    borderRadius: "5px",
                    width: "200px",
                    fontWeight: "300",
                    margin: "60px 0 0 0",
                    fontSize: "16px",
                  }}
                  onClick={this.clearError}
                >
                  Tentar novamente
                </div>
              </div>
            </div>
          </Paper>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary = withRouter(ErrorBoundary);
