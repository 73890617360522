import { httpHelper } from '../_helpers';

export const alunoService = {
  obterListaAlunos,
  salvarAluno,
  pesquisarAlunoPorNome,
  removerAluno,
  obterAlunoPorUuid,
  alterarAluno,
  obterListaAlunoComTurmas,
  obterListaAlunoComTurmasPorResponsavel,
  pesquisarAlunosParaDesabilitar,
  desabilitarAlunos,
  habilitarAlunos
};

function obterListaAlunos(status = true) {
  return httpHelper.get('/seguro/alunos/', { params: { status } });
}

function salvarAluno(dados) {
  return httpHelper.post('/seguro/alunos/', { data: dados });
}

function pesquisarAlunoPorNome(nome) {
  return httpHelper.get('/seguro/alunos/', { data: { nome } });
}

function obterAlunoPorUuid(uuid) {
  return httpHelper.get(`/seguro/alunos/${uuid}`);
}

function alterarAluno(dados) {
  return httpHelper.put(`/seguro/alunos/`, { data: dados });
}

function removerAluno(uuid) {
  return httpHelper.del(`/seguro/alunos/${uuid}`);
}

function obterListaAlunoComTurmas(uuids) {
  return httpHelper.post('/seguro/alunos/pesquisa', { data: uuids });
}

function obterListaAlunoComTurmasPorResponsavel(uuidResponsavel) {
  return httpHelper.get(`/seguro/alunos/responsavel/${uuidResponsavel}/reduzido`);
}

function pesquisarAlunosParaDesabilitar(args) {
  return httpHelper.get(`/seguro/alunos/pesquisa_habilitar`, {
    params: { ...args }
  });
}

function desabilitarAlunos(uuids) {
  return httpHelper.put(`/seguro/alunos/desabilitar_alunos`, {
    data: { uuids }
  });
}

function habilitarAlunos(uuids) {
  return httpHelper.put(`/seguro/alunos/habilitar_alunos`, { data: { uuids } });
}
