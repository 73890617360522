import { httpHelper, dateHelper } from '../_helpers';

const obterTodasAusencias = () => {
  return httpHelper.get('/seguro/ausencias/');
};

const obterListaAusenciasIntervalo = (dataInicio, dataFim) => {
  return httpHelper.get('/seguro/ausencias/', {
    params: {
      data_inicio: dateHelper.formatDate(dataInicio),
      data_fim: dateHelper.formatDate(dataFim)
    }
  });
};

const obterAusencia = (uuid) => {
  return httpHelper.get(`/seguro/ausencias/${uuid}`);
};

export const ausenciaService = {
  obterTodasAusencias,
  obterAusencia,
  obterListaAusenciasIntervalo
};
