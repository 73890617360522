import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { setContext } from 'apollo-link-context';
import { API_URL } from '.';
import { authHelper } from './auth.helper';

const httpLink = new HttpLink({ uri: `${API_URL}/graphql` });
const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        comunicados: relayStylePagination(['sort']) //usando o sort como forma de separar os caches da consulta paginada e da consulta por nao lidos
      }
    }
  }
});

const authLink = setContext(async (_, { headers }) => {
  const token = authHelper.getAccessToken();

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : undefined
    }
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      const errorMessage = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
      console.log(errorMessage);
    });
  }

  if (networkError) {
    const errorMessage = `[GraphQL Network error]: ${networkError}`;
    console.log(errorMessage);
  }
});

const client = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  uri: '/',
  cache,
  defaultOptions: { watchQuery: { fetchPolicy: 'cache-and-network' } }
});

export default client;
