import { httpHelper } from '../_helpers';

export const parametrosService = {
  alterarParametros,
  obterParametros
};

async function obterParametros() {
  return httpHelper.get(`/seguro/parametros/retaguarda/`);
}

async function alterarParametros(dados) {
  return httpHelper.put(`/seguro/parametros/retaguarda/`, { data: dados });
}
