import { httpHelper } from '../_helpers';

export const painelComunicadosService = {
  obterIndicadores,
  obterFiltros,
  obterComunicadosPorRemetente,
  obterComunicadosPorSetor,
  obterComunicadosPorTurma,
  obterComunicadosPorDestinatario,
  obterComunicadosPorSituacao,
  obterComunicadosPorStatus,
  obterComunicadosPorMesStatus,
  obterComunicadosPorMesSituacao,
  obterComunicadosPorAluno,
  obterTempoPorDestinatario,
  obterDetalheComunicados
};

function obterFiltros() {
  return httpHelper.post('/seguro/painel/comunicados/filtros', {});
}

function obterIndicadores(filtro) {
  return httpHelper.post('/seguro/painel/comunicados/indicadores', {
    data: filtro
  });
}

function obterComunicadosPorRemetente(filtro) {
  return httpHelper.post('/seguro/painel/comunicados/graficos/comunicados_remetente', {
    data: filtro
  });
}

function obterComunicadosPorSetor(filtro) {
  return httpHelper.post('/seguro/painel/comunicados/graficos/comunicados_setor', {
    data: filtro
  });
}

function obterComunicadosPorTurma(filtro) {
  return httpHelper.post('/seguro/painel/comunicados/graficos/comunicados_turma', {
    data: filtro
  });
}


function obterComunicadosPorDestinatario(filtro) {
  return httpHelper.post('/seguro/painel/comunicados/graficos/comunicados_destinatario', {
    data: filtro
  });
}

function obterComunicadosPorSituacao(filtro) {
  return httpHelper.post('/seguro/painel/comunicados/graficos/comunicados_situacao', {
    data: filtro
  });
}

function obterComunicadosPorStatus(filtro) {
  return httpHelper.post('/seguro/painel/comunicados/graficos/comunicados_status', {
    data: filtro
  });
}

function obterComunicadosPorMesStatus(filtro) {
  return httpHelper.post('/seguro/painel/comunicados/graficos/comunicados_mes_status', { data: filtro });
}

function obterComunicadosPorMesSituacao(filtro) {
  return httpHelper.post('/seguro/painel/comunicados/graficos/comunicados_mes_situacao', { data: filtro });
}

function obterComunicadosPorAluno(filtro) {
  return httpHelper.post('/seguro/painel/comunicados/graficos/comunicados_aluno', { data: filtro });
}

function obterTempoPorDestinatario(filtro) {
  return httpHelper.post('/seguro/painel/comunicados/graficos/tempo_destinatario', {
    data: filtro
  });
}

function obterDetalheComunicados(filtro) {
  return httpHelper.post('/seguro/painel/comunicados/tabela/comunicados', {
    data: filtro
  });
}
