import Inbox from '@material-ui/icons/Inbox';
import Label from '@material-ui/icons/Label';
import LabelImportant from '@material-ui/icons/LabelImportant';
import LabelOff from '@material-ui/icons/LabelOff';
import LabelOutlined from '@material-ui/icons/LabelOutlined';
import People from '@material-ui/icons/People';

export const SECTIONS = [
  {
    icon: People,
    title: 'Meus comunicados',
    color: '#1A73E8',
    id: 0
  },
  {
    icon: Inbox,
    title: 'Comunicados do Setor',
    color: 'red',
    id: 1
  }
];

export const SITUACOES_COMUNICADOS = [
  {
    id: 1,
    icon: LabelOutlined,
    color: '#faad11' /*'rgb(251,190,67)'*/,
    text: 'A revisar'
  },
  {
    id: 2,
    icon: LabelOff,
    color: '#e82f30'/*'rgb(237,93,94)'*/,
    text: 'Rejeitado'
  },
  {
    id: 3,
    icon: Label,
    color: '#f16f21' /*'rgb(244,142,81)'*/,
    text: 'Programado'
  },
  {
    id: 4,
    icon: LabelImportant,
    color: '#009f5f' /*'rgb(0,210,125)'*/,
    text: 'Enviado'
  }
];
