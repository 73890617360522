import React from 'react';
import { ACCEPT_MIME_TYPES } from '../components/Constantes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const icone = (tipo, tamanho = 'lg', style = {}) => {
  var icon = ACCEPT_MIME_TYPES.filter((mime) => {
    if (tipo.startsWith(mime.type.replace('*', ''))) {
      return mime.icon;
    }
    return null;
  });
  if (icon && icon.length > 0) {
    return <FontAwesomeIcon icon={icon[0].icon} size={tamanho} style={{ ...style }} />;
  }
  return <FontAwesomeIcon icon={'paperclip'} size={tamanho} style={{ ...style }} />;
};

export const anexoHelper = {
  icone
};
