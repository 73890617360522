import { createAsyncAction } from 'redux-promise-middleware-actions';
import { comunicadoService } from '../_services';
import { comunicadoHelper } from '../_helpers';

export const cadastrar = createAsyncAction('CADASTRAR_COMUNICADO', async (to) => {
  const comunicado = comunicadoHelper.getComunicadoFromTO(to);
  return await comunicadoService.cadastrar(comunicado);
});

export const atualizar = createAsyncAction('ATUALIZAR_COMUNICADO', async (to) => {
  const comunicado = comunicadoHelper.getComunicadoFromTO(to);
  return await comunicadoService.atualizar(comunicado);
});

export const revisar = createAsyncAction('REVISAR_COMUNICADO', async (to) => {
  return await comunicadoService.revisar(comunicadoHelper.getComunicadoFromTO(to));
});

export const obterPorUUID = createAsyncAction('OBTER_COMUNICADO_POR_UUID', async (uuid) => {
  return await comunicadoService.obterPorUUID(uuid);
});

export const obterPorStatus = createAsyncAction(
  'OBTER_LISTA_COMUNICADOS_POR_STATUS',
  async (status, page, page_size) => {
    return await comunicadoService.obterPorStatus(status, page, page_size);
  }
);

export const obterAnexoDeComunicado = createAsyncAction(
  'OBTER_ANEXO_COMUNICADO',
  async (uuid, id) => {
    return await comunicadoService.obterAnexoDeComunicado(uuid, id);
  }
);
