import { httpHelper } from '../_helpers';

export const informativoService = {
  obterTodosInformativos,
  gravarInformativo,
  removerInformativo,
  alterarInformativo,
  obterInformativoPorUuid
};

function obterTodosInformativos(status = true) {
  return httpHelper.get('/seguro/informativos/retaguarda/', {
    params: { status }
  });
}

function gravarInformativo(dados) {
  return httpHelper.post('/seguro/informativos/retaguarda/', { data: dados });
}

function obterInformativoPorUuid(uuid) {
  return httpHelper.get(`/seguro/informativos/retaguarda/${uuid}`);
}

function alterarInformativo(dados) {
  return httpHelper.put(`/seguro/informativos/retaguarda/`, { data: dados });
}

async function removerInformativo(uuid) {
  return httpHelper.del(`/seguro/informativos/retaguarda/${uuid}`);
}
