import { httpHelper } from '../_helpers';

/**
 * Persiste um setor.
 * @param {*} setor
 * @returns
 */
const persistir = (setor) => {
  if (setor) {
    if (setor.uuid) {
      return httpHelper.put('/seguro/setores/', { data: setor });
    } else {
      return httpHelper.post('/seguro/setores/', { data: setor });
    }
  }
};

/**
 * Remove um setor.
 * @param {*} uuid
 * @returns
 */
const remover = (uuid) => {
  return httpHelper.del(`/seguro/setores/${uuid}`);
};

/**
 * Obtém todos os setores ou se informado um UUID obtém o setor do UUID.
 * @param {*} uuid
 * @param {*} status
 * @returns
 */
const obter = (uuid) => {
  return httpHelper.get(`/seguro/setores/${uuid}`);
};

/**
 * Consulta paginada para a tela de listagem de Setores.
 * @param {*} params
 * @returns
 */
const listarPaginado = (params) => {
  return httpHelper.get('/seguro/setores/paginado', { params: params });
};

/**
 * Listagem de setores para a tela de Criar/Alterar Funcionário.
 * @param {*} params
 * @returns
 */
const listar = (params) => {
  return httpHelper.get('/seguro/setores/');
};

export const setorService = {
  persistir,
  remover,
  obter,
  listarPaginado,
  listar
};
