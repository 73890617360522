import { httpHelper } from '../_helpers';

// TODO os serviços de ativar/desativar em lote
// TODO os serviços de ativar/desativar por id
// TODO perguntar onde está o serviço de desativacao por id
export const turmaService = {
  obterTodasTurmas,
  obterTurmaPorUuid,
  obterTurmasPorAno,
  obterTurmasPorNome,
  gravarTurma,
  alterarTurma,
  removerTurma,
  migrarTurma,
  obterAnosTurmas
};

function obterTodasTurmas(status = true, devePossuirAlunos = false) {
  return httpHelper.get('/seguro/turmas/', {
    params: { status, devePossuirAlunos }
  });
}

function obterTurmaPorUuid(uuid) {
  return httpHelper.get(`/seguro/turmas/${uuid}`);
}

function obterTurmasPorNome(nome) {
  return httpHelper.get('/seguro/turmas/pesquisa/nome', {
    params: { valor: nome }
  });
}

function obterTurmasPorAno(ano) {
  return httpHelper.get('/seguro/turmas/pesquisa/ano', {
    params: { valor: ano }
  });
}

function gravarTurma(dados) {
  return httpHelper.post('/seguro/turmas/', { data: dados });
}

function alterarTurma(dados) {
  return httpHelper.put('/seguro/turmas/', { data: dados });
}

function removerTurma(uuid) {
  return httpHelper.del(`/seguro/turmas/${uuid}`);
}

function migrarTurma(anoLetivoDestino, disciplinasEProfessores, dados) {
  return httpHelper.post(
    `/seguro/turmas/importar?disciplinas_e_professores=${
      disciplinasEProfessores ? 'true' : 'false'
    }&ano_letivo_destino=${anoLetivoDestino}`,
    { data: dados }
  );
}

function obterAnosTurmas() {
  return httpHelper.get(`/seguro/turmas/anos`);
}
