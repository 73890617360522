import { authHelper } from './';

const tiposDict = {
  'Lanche da Manhã': {
    value: 'Lanche da Manhã',
    label: 'Lanche da Manhã',
    avatar: { label: 'LM', cor: '#ef6c00' },
    filterLabel: 'tipos'
  },
  Almoço: {
    value: 'Almoço',
    label: 'Almoço',
    avatar: { label: 'AL', cor: '#c62828' },
    filterLabel: 'tipos'
  },
  'Lanche da Tarde': {
    value: 'Lanche da Tarde',
    label: 'Lanche da Tarde',
    avatar: { label: 'LT', cor: '#1565c0' },
    filterLabel: 'tipos'
  },
  Jantar: {
    value: 'Jantar',
    label: 'Jantar',
    avatar: { label: 'JT', cor: '#2e7d32' },
    filterLabel: 'tipos'
  }
};

const tipos = Object.keys(tiposDict).map(function (v) {
  return tiposDict[v];
});

const tipos_nomes = Object.keys(tiposDict);

const getTipoPorNome = (tipo) => {
  return tiposDict[tipo];
};

const convertFiltroEmTO = (filtro) => {
  let to = {
    tipos: (filtro.tipos || []).map((tipo) => tipo),
    cardapios: (filtro.cardapios || []).map((cardapio) => cardapio.uuid),
    ingredientes: (filtro.ingredientes || []).map((ingrediente) => ingrediente.uuid)
  };
  return { ...to };
};

const convertCardapioEmTO = (cardapio) => {
  let to = {
    uuid: cardapio.uuid,
    nome: cardapio.nome || '',
    tipo: tiposDict[cardapio.tipo],
    foto: cardapio.foto,
    alergico: cardapio.alergico,
    ingredientes: (cardapio.ingredientes || []).map((ingrediente) => ({
      value: ingrediente.uuid,
      label: ingrediente.nome,
      filterLabel: 'ingredientes'
    }))
  };
  return { ...to };
};

const convertTOEmCardapio = (to) => {
  const cardapio = {
    uuid: to.uuid,
    nome: to.nome,
    tipo: to.tipo.value,
    foto: to.foto,
    alergico: !!to.alergico,
    ingredientes: (to.ingredientes || []).map((ingrediente) => ({
      uuid: ingrediente.value
    })),
    funcionario: {
      uuid: authHelper.getClaimsUsuarioLogado().uuid
    }
  };

  return { ...cardapio };
};

const convertTOEmAssociacaoCardapioTurma = (to) => {
  let listaRefeicoes = [];

  if (to.lanche_manha && to.lanche_manha.value) {
    listaRefeicoes.push(to.lanche_manha.value.uuid);
  }

  if (to.almoco && to.almoco.value) {
    listaRefeicoes.push(to.almoco.value.uuid);
  }

  if (to.lanche_tarde && to.lanche_tarde.value) {
    listaRefeicoes.push(to.lanche_tarde.value.uuid);
  }

  if (to.jantar && to.jantar.value) {
    listaRefeicoes.push(to.jantar.value.uuid);
  }

  if (to.lanche_manha_alergico && to.lanche_manha_alergico.value) {
    listaRefeicoes.push(to.lanche_manha_alergico.value.uuid);
  }

  if (to.almoco_alergico && to.almoco_alergico.value) {
    listaRefeicoes.push(to.almoco_alergico.value.uuid);
  }

  if (to.lanche_tarde_alergico && to.lanche_tarde_alergico.value) {
    listaRefeicoes.push(to.lanche_tarde_alergico.value.uuid);
  }

  if (to.jantar_alergico && to.jantar_alergico.value) {
    listaRefeicoes.push(to.jantar_alergico.value.uuid);
  }

  //datas: Object.values(to.datas).map(data => getLocalISOTime(data)),
  const cardapios = {
    uuids: [...listaRefeicoes],
    datas: Object.values(to.datas),
    funcionario: {
      uuid: authHelper.getClaimsUsuarioLogado().uuid
    }
  };

  return { cardapios: cardapios };
};

export const cardapioHelper = {
  convertTOEmCardapio,
  convertCardapioEmTO,
  convertTOEmAssociacaoCardapioTurma,
  convertFiltroEmTO,
  getTipoPorNome,
  tipos,
  tipos_nomes
};
