import {
  obterTodasRotinasAlunos,
  gravarRotinaAluno,
  obterRotinaAlunoPorUuid,
  alterarRotinaAluno,
  obterTodasRotinasTurmas,
  gravarRotinaTurma,
  obterRotinaTurmaPorUuid,
  alterarRotinaTurma,
  obterRotinasTurmasPaginada,
  obterRotinasAlunosPorUuidData
} from '../_actions/rotina.actions';

const initialState = {
  rotinas_aluno: [],
  rotina_aluno: {},
  rotinas_turma: [],
  rotina_turma: {},
  rotinas_turma_paginada: [],
  rotinas_aluno_turma: []
};

export const rotinaReducer = (state = initialState, action) => {
  switch (action.type) {
    case String(gravarRotinaAluno.fulfilled):
      return { ...state, rotina_aluno: action.payload.data };
    case String(obterTodasRotinasAlunos.fulfilled):
      return { ...state, rotinas_aluno: action.payload.data };
    case String(obterRotinaAlunoPorUuid.fulfilled):
      return { ...state, rotina_aluno: action.payload.data };
    case String(alterarRotinaAluno.fulfilled):
      return { ...state, rotina_aluno: action.payload.data };
    case String(gravarRotinaTurma.fulfilled):
      return { ...state, rotina_turma: action.payload.data };
    case String(obterTodasRotinasTurmas.fulfilled):
      return { ...state, rotinas_turma: action.payload.data };
    case String(obterRotinaTurmaPorUuid.fulfilled):
      return { ...state, rotina_turma: action.payload.data };
    case String(alterarRotinaTurma.fulfilled):
      return { ...state, rotina_turma: action.payload.data };
    case String(obterRotinasTurmasPaginada.fulfilled):
      return { ...state, rotinas_turma_paginada: action.payload.data };
    case String(obterRotinasAlunosPorUuidData.fulfilled):
      return { ...state, rotinas_aluno_turma: action.payload.data };
    default:
      return state;
  }
};
