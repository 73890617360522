import { combineReducers } from 'redux';

// Manter
import { notificacao } from './notificacao.reducer';
import { painelComunicadosReducer } from './painel.comunicados.reducer';

// Entender melhor para que serve
import { loadingReducer } from './loading.reducer';

// Remover
import { parametrosReducer } from './parametros.reducer';
import { informativoReducer } from './informativo.reducer';
import { disciplinaReducer } from './disciplina.reducer';
import { dominiosReducer } from './dominios.reducer';

import { comunicadoPocReducer } from './comunicado.poc.reducer';
import { responsavelReducer } from './responsavel.reducer';
import { funcionarioReducer } from './funcionario.reducer';
import { cardapioReducer } from './cardapio.reducer';
import { ingredienteReducer } from './ingrediente.reducer';
import { alunoReducer } from './aluno.reducer';
import { turmaReducer } from './turma.reducer';
import { rotinaReducer } from './rotina.reducer';
import { ausenciaReducer } from './ausencia.reducer';

export default () =>
  combineReducers({
    NotificacaoReducer: notificacao,
    ResponsavelReducer: responsavelReducer,
    FuncionarioReducer: funcionarioReducer,
    LoadingReducer: loadingReducer,
    AlunoReducer: alunoReducer,
    DominiosReducer: dominiosReducer,
    DisciplinaReducer: disciplinaReducer,
    AusenciaReducer: ausenciaReducer,
    ComunicadoPocReducer: comunicadoPocReducer,
    TurmaReducer: turmaReducer,
    PainelComunicadosReducer: painelComunicadosReducer,
    CardapioReducer: cardapioReducer,
    IngredienteReducer: ingredienteReducer,
    RotinaReducer: rotinaReducer,
    InformativoReducer: informativoReducer,
    ParametrosReducer: parametrosReducer
  });
