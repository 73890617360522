import { dateHelper } from './';

const convertResponsavelEmTO = (responsavel) => {
  if (responsavel !== undefined) {
    if (Array.isArray(responsavel)) {
      return responsavel.map(convertResponsavelEmTO);
    } else {
      let to = {
        uuid: responsavel.uuid,
        nome: responsavel.nome,
        email: responsavel.email,
        cpf: responsavel.cpf || '',
        dataNascimento: responsavel.data_nascimento
          ? dateHelper.parseDataTextoEmDataObject(responsavel.data_nascimento)
          : null,
        observacao: responsavel.informacoes_adicionais || '',
        cep: responsavel.endereco ? responsavel.endereco.cep || '' : '',
        bairro: responsavel.endereco ? responsavel.endereco.bairro || '' : '',
        logradouro: responsavel.endereco ? responsavel.endereco.logradouro || '' : '',
        numero: responsavel.endereco ? responsavel.endereco.numero || '' : '',
        complemento: responsavel.endereco ? responsavel.endereco.complemento || '' : '',
        ufMuni: {
          municipio:
            responsavel.endereco && responsavel.endereco.municipio
              ? {
                  label: responsavel.endereco.municipio.nome,
                  value: responsavel.endereco.municipio.codigo
                }
              : '',
          uf:
            responsavel.endereco && responsavel.endereco.municipio
              ? {
                  label: responsavel.endereco.municipio.uf.nome,
                  value: responsavel.endereco.municipio.uf.codigo
                }
              : ''
        },
        telefones: []
      };

      if (responsavel.telefones) {
        responsavel.telefones.forEach((tel) => {
          const telefone = {
            ddd: tel.ddd,
            numero: tel.numero,
            aceitaContato: tel.aceita_contato_mensageiro,
            mensageiro: tel.mensageiro
          };
          to.telefones.push(telefone);
        });
      }

      return { ...to };
    }
  }
};

const convertTOEmResponsavel = (to) => {
  if (to !== undefined) {
    if (Array.isArray(to)) {
      return to.map(convertTOEmResponsavel);
    } else {
      let responsavel = {
        uuid: to.uuid,
        nome: to.nome,
        email: to.email.toLowerCase(),
        cpf: String(to.cpf).replace(/[^\d]+/g, ''),
        data_nascimento: dateHelper.formatDate(to.dataNascimento),
        informacoes_adicionais: to.observacao.trim() || null,
        endereco: {
          cep: String(to.cep).trim() !== '' ? String(to.cep).replace(/[^\d]+/g, '') : null,
          bairro: to.bairro.trim() || null,
          logradouro: to.logradouro.trim() || null,
          numero: to.numero.trim() || null,
          complemento: to.complemento.trim() || null,
          municipio: {
            codigo: to.ufMuni.municipio.value
          }
        },
        telefones: []
      };

      if (to.telefones) {
        to.telefones.forEach((tel) => {
          const telefone = {
            ddd: tel.ddd,
            numero: String(tel.numero).replace(/[^\d]+/g, ''),
            aceita_contato_mensageiro: tel.aceitaContato,
            mensageiro: tel.mensageiro
          };
          responsavel.telefones.push(telefone);
        });
      }

      if (
        !responsavel.endereco.cep &&
        !responsavel.endereco.bairro &&
        !responsavel.endereco.logradouro &&
        !responsavel.endereco.numero &&
        !responsavel.endereco.complemento &&
        (!responsavel.endereco.municipio || !responsavel.endereco.municipio.codigo)
      ) {
        // delete responsavel.endereco;
        responsavel.endereco = null;
      }

      if (responsavel.cpf === '') {
        responsavel.cpf = null;
      }

      return { ...responsavel };
    }
  }
};

export const responsavelHelper = {
  convertResponsavelEmTO,
  convertTOEmResponsavel
};
