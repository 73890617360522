import { httpHelper } from '../_helpers';

/**
 * Cadastra um novo comunicado.
 * * @param {*} comunicado
 */
const cadastrar = async (comunicado) => {
  return httpHelper.post('/seguro/comunicados/', { data: comunicado });
};

/**
 * Atualiza um comunicado.
 * @param {*} comunicado
 */
const atualizar = async (comunicado) => {
  return httpHelper.put('/seguro/comunicados/', { data: comunicado });
};

/**
 * Revisa um comunicado.
 * @param {*} comunicado
 */
const revisar = async (comunicado) => {
  return httpHelper.put('/seguro/comunicados/revisar', { data: comunicado });
};

/**
 * Obtém uma lista de comunicados pelo seu status.
 */
const obterPorStatus = async (status, page, page_size) => {
  return httpHelper.get(`/seguro/comunicados/status/${status}`, {
    params: { page, page_size }
  });
};

/**
 * Recupera um comunicado pelo UUID.
 * @param {*} uuid
 */
const obterPorUUID = async (uuid) => {
  return httpHelper.get(`/seguro/comunicados/${uuid}`);
};

/**
 * Recupera um anexo do comunicado pelo id.
 * @param {*} uuid
 * @param {*} id
 */
const obterAnexoDeComunicado = async (uuid, id) => {
  return httpHelper.get(`/seguro/comunicados/${uuid}/anexos/${id}`);
};

/**
 * Retorna uma lista de comunicados pelo setor do funcionário logado.
 */
const obterComunicadosSetorFuncionario = async () => {
  return httpHelper.get('/seguro/comunicados/setor');
};

/**
 * Retorna uma página de comunicados pelo setor do funcionário logado.
 */
const obterComunicadosSetorFuncionarioPaginada = async (
  page,
  page_size,
  order_by_field,
  order_by_direction,
  filters
) => {
  if (filters) {
    return httpHelper.post('/seguro/comunicados/setor/paginada', {
      params: { page, page_size, order_by_field, order_by_direction },
      data: [].concat(filters)
    });
  } else {
    return httpHelper.post('/seguro/comunicados/setor/paginada', {
      params: { page, page_size, order_by_field, order_by_direction }
    });
  }
};

export const comunicadoService = {
  cadastrar,
  atualizar,
  revisar,
  obterPorUUID,
  obterPorStatus,
  obterAnexoDeComunicado,
  obterComunicadosSetorFuncionario,
  obterComunicadosSetorFuncionarioPaginada
};
