import { createAsyncAction } from 'redux-promise-middleware-actions';
import { ingredienteService } from '../_services/ingrediente.service';

export const gravarIngrediente = createAsyncAction('INGREDIENTE_SALVAR', async (ingrediente) => {
  return await ingredienteService.gravarIngrediente(ingrediente);
});

export const obterListaIngredientes = createAsyncAction('INGREDIENTE_OBTER_LISTA', async () => {
  return await ingredienteService.obterTodosIngredientes();
});

export const obterListaIngredientesUtilizados = createAsyncAction(
  'INGREDIENTE_OBTER_LISTA_UTILIZADOS_CARDAPIOS',
  async () => {
    return await ingredienteService.obterTodosIngredientesUtilizados();
  }
);

export const obterListaIngredientesPaginado = createAsyncAction(
  'INGREDIENTE_OBTER_LISTA_PAGINADA',
  async (page, pageSize) => {
    return await ingredienteService.obterTodosIngredientesPaginado(true, page, pageSize);
  }
);

export const pesquisaIngredientesPaginado = createAsyncAction(
  'INGREDIENTE_PESQUISA_PAGINADA',
  async (valor, page, pageSize) => {
    return await ingredienteService.pesquisaIngredientesPaginado(valor, true, page, pageSize);
  }
);

export const removerIngrediente = createAsyncAction('INGREDIENTE_REMOVER', async (uuid) => {
  return await ingredienteService.removerIngrediente(uuid);
});

export const obterIngrediente = createAsyncAction('INGREDIENTE_OBTER', async (uuid) => {
  return await ingredienteService.obterIngredientePorUuid(uuid);
});

export const alterarIngrediente = createAsyncAction(
  'INGREDIENTE_ALTERAR',
  async (id, ingrediente) => {
    return await ingredienteService.alterarIngrediente(id, ingrediente);
  }
);
