export const CAMPO_OBRIGATORIO = "O campo '{0}' é obrigatório.";
export const CAMPO_INVALIDO = '{0} inválido';
export const DATA_FIM_MAIOR_QUE_INICIO = 'A data fim deve ser maior que a data de início.';

String.prototype.format = function () {
  var args = [].slice.call(arguments);
  return this.replace(/(\{\d+\})/g, function (a) {
    return args[+a.substr(1, a.length - 2) || 0];
  });
};
