import React from 'react';
import Cake from '@material-ui/icons/Cake';
import Favorite from '@material-ui/icons/Favorite';
import Star from '@material-ui/icons/Star';
import Restaurant from '@material-ui/icons/Restaurant';
import School from '@material-ui/icons/School';
import Group from '@material-ui/icons/Group';
import { authHelper, dateHelper } from './';

/**
 * Lista de ícones a apresentar em tela.
 */
const iconesDict = {
  school: { value: 'school', label: <School /> },
  cake: { value: 'cake', label: <Cake /> },
  favorite: { value: 'favorite', label: <Favorite /> },
  star: { value: 'star', label: <Star /> },
  restaurant: { value: 'restaurant', label: <Restaurant /> },
  group: { value: 'group', label: <Group /> }
};

/**
 * Obtém o ícone da lista.
 */
export const icones = Object.keys(iconesDict).map(function (v) {
  return iconesDict[v];
});

/**
 * Obtém o ícone da lista pelo nome (útil quando vem do objeto persistido no banco).
 * @param {*} name
 * @returns
 */
export function getIconeByName(name) {
  return iconesDict[name];
}

/**
 * Converte o objeto do servidor para o de tela.
 * @param {*} evento
 * @returns
 */
export const convertEventoEmTO = (evento) => {
  if (evento) {
    if (Array.isArray(evento)) {
      return evento.map(convertEventoEmTO);
    } else {
      let to = {
        uuid: evento.uuid,
        titulo: evento.titulo,
        icone: getIconeByName(evento.icone),
        data_inicio: dateHelper.parseDataHoraTextoEmDataObject(evento.data_inicio),
        data_fim: dateHelper.parseDataHoraTextoEmDataObject(evento.data_fim)
      };
      return { ...to };
    }
  }
};

/**
 * Converte o objeto de tela para o objeto que o servidor entende.
 * @param {*} to
 * @returns
 */
export const convertTOEmEvento = (to) => {
  const data_inicio = dateHelper.getLocalISOTime(to.data_inicio);
  const data_fim = to.data_fim ? dateHelper.getLocalISOTime(to.data_fim) : data_inicio;

  return {
    uuid: to.uuid ? to.uuid : undefined,
    titulo: to.titulo ? to.titulo : undefined,
    icone: to.icone.value ? to.icone.value : undefined,
    data_inicio: data_inicio ? data_inicio : undefined,
    data_fim: data_fim ? data_fim : undefined,
    funcionario: {
      uuid: authHelper.getClaimsUsuarioLogado().uuid
    }
  };
};

export const eventoHelper = {
  icones,
  convertEventoEmTO,
  convertTOEmEvento,
  getIconeByName
};
