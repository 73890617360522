import { httpHelper } from '../_helpers';

export const responsavelService = {
  obterTodosResponsaveis,
  obterResponsavelPorUuid,
  gravarResponsavel,
  removerResponsavel,
  alterarResponsavel
};

function obterTodosResponsaveis(status = true) {
  return httpHelper.get('/seguro/responsaveis/', { params: { status } });
}

function obterResponsavelPorUuid(uuid) {
  return httpHelper.get(`/seguro/responsaveis/${uuid}`);
}

function gravarResponsavel(dados) {
  return httpHelper.post('/seguro/responsaveis/', { data: dados });
}

function alterarResponsavel(dados) {
  return httpHelper.put('/seguro/responsaveis/', { data: dados });
}

async function removerResponsavel(uuid) {
  return httpHelper.del(`/seguro/responsaveis/${uuid}`);
}
