export const appStyles = (theme) => ({
  wrapWarning: {
    width: '100%',
    position: 'relative'
  },
  warningLoginExpired: {
    backgroundColor: 'rgba(255,105,97,0.5)',
    lineHeight: `${theme.spacing(4)}px`,
    height: theme.spacing(4),
    width: '100%',
    textAlign: 'center',
    position: 'absolute',
    zIndex: '99'
  }
});
