import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { HashRouter, Route, Switch } from 'react-router-dom';

import { MuiThemeProvider } from '@material-ui/core/styles';

import ScrollToTop from './components/ScrollToTop';
import Notifier from './components/NotificacaoComponent';
import { theme } from './components/styles/Theme';

import App from './containers/app/App';
import DefinirNovaSenha from './containers/login/DefinirNovaSenha';
import EsqueciSenha from './containers/login/EsqueciSenha';
import SignIn from './containers/login/Login';
import ConfirmacaoVerificacaoConta from './containers/login/ConfirmacaoVerificacaoConta';

import { store } from './store';
import Rotas from './_constants/rotas';
import { MainProvider } from './provider';

/**
 * A ordem dos imports do CSS irá afetar a hierarquia dos estilos!
 * Primeiro importa-se os dos componentes e depois os customizados.
 */
import './fontawesome';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'sanitize.css/sanitize.css';
import 'react-chat-elements/dist/main.css';
import './assets/css/inbox.scss';
import './assets/css/inbox.scss';
import 'sanitize.css/sanitize.css';
import './index.css';
import './sidebar.css';
import { ApolloProvider } from '@apollo/client';
import client from './_helpers/apollo.client.helper';

if (module.hot) {
  module.hot.accept();
}

const target = document.getElementById('root');

render(
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <HashRouter>
        <SnackbarProvider
          preventDuplicate
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}>
          <Notifier />
          <Switch>
            <Route exact path={Rotas.autenticacao.login} component={SignIn} />
            <Route exact path={Rotas.autenticacao.esqueci_senha} component={EsqueciSenha} />
            <Route
              exact
              path={Rotas.autenticacao.definir_nova_senha}
              component={DefinirNovaSenha}
            />
            <Route
              exact
              path={Rotas.autenticacao.verificacao_conta}
              component={ConfirmacaoVerificacaoConta}
            />
            <ScrollToTop>
              <ApolloProvider client={client}>
                <MainProvider>
                  <App />
                </MainProvider>
              </ApolloProvider>
            </ScrollToTop>
          </Switch>
        </SnackbarProvider>
      </HashRouter>
    </Provider>
  </MuiThemeProvider>,
  target
);
