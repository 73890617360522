import React, { Component, Suspense } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

export default class SuspenseBoundary extends Component {
  render() {
    let fallback = (
      <Box height={'100%'} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );

    return <Suspense fallback={fallback}>{this.props.children}</Suspense>;
  }
}
