import { createTheme } from '@material-ui/core/styles';
import '../../assets/fonts/Public-Sans.css';
import './Theme.css';

export let baseTheme = {
  typography: {
    fontFamily: "'Inter', 'Helvetica', 'Arial', sans-serif",
    h5: {
      fontSize: '2.0rem',
      '@media (min-width:600px)': {
        fontSize: '2.5rem'
      },
      fontWeight: '300'
    }
  },
  palette: {
    text: {
      primary: 'rgba(60, 72, 88, 1.0)',
      secondary: 'rgba(60, 72, 88, 0.7)',
      disabled: 'rgba(0, 0, 0, 0.30)',
      hint: 'rgba(0, 0, 0, 0.38)'
    },
    primary: {
      // light: será calculada com base em palette.primary.main,
      main: '#376FD0'
      // dark: será calculada com base em palette.primary.main,
      // contrastText: será calculada para contrastar com palette.primary.main
    },
    // secondary: {
    // light: '#0066ff',
    // main: '#0044ff',
    // dark: será calculada com base palette.secondary.main,
    // contrastText: '#ffcc00',
    // },
    // Usado por `getContrastText()` para maximizar o contraste entre
    // o plano de fundo e o texto.
    // contrastThreshold: 3,
    // Usado pelas funções abaixo para mudança de uma cor de luminância por aproximadamente
    // dois índices dentro de sua paleta tonal.
    // Por exemplo, mude de Red 500 para Red 300 ou Red 700.
    // tonalOffset: 0.2,
    background: {}
  },
  shadows: [...Array(24)].map((x, i) => `0 0 ${i * 2}px rgba(0,0,0,0.20)`),
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: 'rgb(239,242,244)'
        },
        'html, body, #root': {
          minHeight: '100vh'
        }
      }
    }
  }
};

export const theme = createTheme(baseTheme);
global._createTheme = createTheme;
global._theme = theme;
