import { createAsyncAction } from 'redux-promise-middleware-actions';
import { cardapioService } from '../_services/cardapio.service';

export const gravarCardapio = createAsyncAction('CARDAPIO_SALVAR', async (cardapio) => {
  return await cardapioService.gravarCardapio(cardapio);
});

export const obterListaCardapios = createAsyncAction('CARDAPIO_OBTER_LISTA', async () => {
  return await cardapioService.obterTodosCardapios();
});

export const obterListaCardapiosReduzida = createAsyncAction(
  'CARDAPIO_OBTER_LISTA_REDUZIDA',
  async () => {
    return await cardapioService.obterTodosCardapiosReduzido();
  }
);

export const obterListaCardapiosPaginada = createAsyncAction(
  'CARDAPIO_OBTER_LISTA_PAGINADA',
  async (pagina, tamanhoPagina) => {
    return await cardapioService.obterListaCardapiosPaginada(pagina, tamanhoPagina);
  }
);

export const removerCardapio = createAsyncAction('CARDAPIO_REMOVER', async (uuid) => {
  return await cardapioService.removerCardapio(uuid);
});

export const obterCardapio = createAsyncAction('CARDAPIO_OBTER', async (uuid) => {
  return await cardapioService.obterCardapioPorUuid(uuid);
});

export const pesquisarCardapios = createAsyncAction('CARDAPIO_PESQUISAR', async (uuids) => {
  return await cardapioService.pesquisarCardapios(uuids);
});

export const alterarCardapio = createAsyncAction('CARDAPIO_ALTERAR', async (id, cardapio) => {
  return await cardapioService.alterarCardapio(id, cardapio);
});

export const associarTurma = createAsyncAction(
  'CARDAPIO_ASSOCIAR_TURMA',
  async (uuid_turma, cardapios) => {
    return await cardapioService.associarTurma(uuid_turma, cardapios);
  }
);

export const obterAssociacaoTurma = createAsyncAction(
  'CARDAPIO_OBTER_ASSOCIAR_TURMA',
  async (turma, data_inicio, data_fim) => {
    return await cardapioService.obterAssociacaoTurma(turma, data_inicio, data_fim);
  }
);

export const pesquisaFiltro = createAsyncAction('CARDAPIO_PESQUISA_FILTRO', async (filtro) => {
  return await cardapioService.pesquisaFiltro(filtro);
});

export const pesquisaFiltroPaginado = createAsyncAction(
  'CARDAPIO_PESQUISA_FILTRO_PAGINADO',
  async (filtro, pagina, tamanhoPagina) => {
    return await cardapioService.pesquisaFiltroPaginado(filtro, pagina, tamanhoPagina);
  }
);

export const removerAssociacaoTurma = createAsyncAction(
  'CARDAPIO_REMOVER_ASSOCIAR_TURMA',
  async (idsAssociacao) => {
    return await cardapioService.removerAssociacaoTurma(idsAssociacao);
  }
);
