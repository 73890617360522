const montaMensagem = (mensagem, tipo) => ({
  message: mensagem,
  options: {
    key: new Date().getTime() + Math.random(),
    variant: tipo,
    preventDuplicate: true
  }
});

const mensagemSucesso = (mensagem) => montaMensagem(mensagem, 'success');

const mensagemErro = (mensagem) => montaMensagem(mensagem, 'error');

const mensagemWarning = (mensagem) => montaMensagem(mensagem, 'warning');

const mensagemInfo = (mensagem) => montaMensagem(mensagem, 'info');

export const notificacaoHelper = {
  mensagemSucesso,
  mensagemErro,
  mensagemWarning,
  mensagemInfo
};
