import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';

import createRootReducer from './_reducers';

const initialState = {};
const enhancers = [];
const middleware = [thunk, promiseMiddleware];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(createRootReducer(), initialState, composedEnhancers);

if (process.env.NODE_ENV === 'development') {
  global._store = store;
  store.subscribe(() => (global._state = store.getState()));
}

export { store };
