import { obterParametros, alterarParametros } from '../_actions/parametros.actions';

const initialState = {
  parametros: [],
  parametrosAlterados: null
};

export const parametrosReducer = (state = initialState, action) => {
  switch (action.type) {
    case obterParametros.pending + '':
      return {
        ...state,
        carregandoObterParametros: true
      };
    case alterarParametros.pending + '':
      return {
        ...state,
        carregandoAlterarParametros: true,
        parametrosAlterados: null
      };
    case String(obterParametros.fulfilled):
      return {
        ...state,
        carregandoObterParametros: false,
        parametros: action?.payload?.data,
        dicionarioParametros: action?.payload?.data?.reduce((obj, item) => {
          return { ...obj, [item.nome]: item.valor };
        }, {})
      };
    case String(alterarParametros.fulfilled):
      return {
        ...state,
        carregandoAlterarParametros: false,
        parametrosAlterados: true
      };
    default:
      return state;
  }
};
