import { obterListaAusencias, obterAusencia, obterListaAusenciasIntervalo } from '../_actions';

const initialState = { ausencias: [], ausencia: {} };

export const ausenciaReducer = (state = initialState, action) => {
  switch (action.type) {
    case String(obterListaAusencias.fulfilled):
      return { ...state, ausencias: action.payload };
    case String(obterListaAusenciasIntervalo.fulfilled):
      return { ...state, ausenciasIntervalo: action.payload };
    case String(obterAusencia.fulfilled):
      return { ...state, ausencia: action.payload.data };
    default:
      return state;
  }
};
