import {
  gravarIngrediente,
  obterListaIngredientes,
  obterListaIngredientesPaginado,
  removerIngrediente,
  obterIngrediente,
  alterarIngrediente,
  pesquisaIngredientesPaginado,
  obterListaIngredientesUtilizados
} from '../_actions/ingrediente.actions';

const initialState = {
  ingredientes: [],
  ingrediente: {},
  paginacao: {},
  ingredientesUso: []
};

export const ingredienteReducer = (state = initialState, action) => {
  switch (action.type) {
    case String(gravarIngrediente.fulfilled):
      return { ...state, ingrediente: action.payload.data };
    case String(obterListaIngredientes.fulfilled):
      return { ...state, ingredientes: action.payload.data };
    case String(obterListaIngredientesPaginado.fulfilled):
      return { ...state, paginacao: action.payload.data };
    case String(pesquisaIngredientesPaginado.fulfilled):
      return { ...state, paginacao: action.payload.data };
    case String(obterIngrediente.fulfilled):
      return { ...state, ingrediente: action.payload.data };
    case String(removerIngrediente.fulfilled):
      return { ...state, ingrediente: {} };
    case String(alterarIngrediente.fulfilled):
      return { ...state, ingrediente: action.payload.data };
    case String(obterListaIngredientesUtilizados.fulfilled):
      return { ...state, ingredientesUso: action.payload.data };
    default:
      return state;
  }
};
