import { httpHelper } from '../_helpers';

/**
 * Persiste um funcionário.
 * @param {*} funcionario
 * @returns
 */
const persistir = (funcionario) => {
  if (funcionario) {
    if (funcionario.uuid) {
      return httpHelper.put('/seguro/funcionarios/', { data: funcionario });
    } else {
      return httpHelper.post('/seguro/funcionarios/', { data: funcionario });
    }
  }
};

/**
 * Remove um funcionário.
 * @param {*} uuid
 * @returns
 */
const remover = (uuid) => {
  return httpHelper.del(`/seguro/funcionarios/${uuid}`);
};

/**
 * Remove um professor.
 * @param {*} uuid
 * @returns
 */
const removerProfessor = (uuid) => {
  return httpHelper.del(`/seguro/funcionarios/${uuid}`, {
    params: {
      tipo_professor: true
    }
  });
};

/**
 * Obtém todos os funcionários. Se um UUID for informado, retorna apenas o funcionário solicitado.
 * @param {*} uuid
 * @param {*} is_professor
 * @returns
 */
const obter = (uuid = undefined, status = true, is_professor = false) => {
  if (uuid) {
    return httpHelper.get(`/seguro/funcionarios/${uuid}`);
  } else {
    return httpHelper.get('/seguro/funcionarios/', {
      params: {
        status,
        tipo_professor: is_professor
      }
    });
  }
};

const obterDisciplinasFuncionario = (uuid) => {
  return httpHelper.get(`/seguro/funcionarios/professor/${uuid}`);
};

const obterPorEmail = (email) => {
  return httpHelper.get('/seguro/funcionarios/pesquisa/email', {
    params: { status: true, valor: email }
  });
};

export const funcionarioService = {
  persistir,
  remover,
  removerProfessor,
  obter,
  obterPorEmail,
  obterDisciplinasFuncionario
};
