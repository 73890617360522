import { useLazyQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { SECTIONS, SITUACOES_COMUNICADOS } from './containers/comunicadosv2/comunicadoUtils';
import { COMUNICADOS_PAGINADO } from './graphql/queries/comunicado';
import { authHelper } from './_helpers';

const MainContext = React.createContext({
  obterComunicados: () => {},

  page: 0,
  lastPageFetched: 0,
  loading: false,
  error: undefined,
  comunicados: [],
  pageInfo: undefined,
  edgeCount: undefined,
  totalCount: undefined,
  networkStatus: undefined,

  nextPage: undefined,
  previousPage: undefined,

  filterEmailByText: undefined,
  filterEmailBySituacao: undefined,
  filterEmailBySection: undefined,

  executadoPrimeiraVez: false
});

export const TAMANHO_PAGINA_COMUNICADOS = 15;

const situacoesSelecionadas = SITUACOES_COMUNICADOS.map((sit) => ({
  ...sit,
  selected: true
}));

const secoes = SECTIONS.map((sec) => ({
  ...sec,
  selected: sec.id === 0
}));

export const MainProvider = ({ children }) => {
  const [filter, setFilter] = useState({
    funcionarioUuid: authHelper.getUserUUID()
  });

  const [page, setPage] = useState(0);
  const [lastPageFetched, setLastPageFetched] = useState(0);
  const [sections, setSections] = useState([...secoes]);
  const [textoBusca, setTextoBusca] = React.useState('');
  const [situacoes, setSituacoes] = useState([...situacoesSelecionadas]);

  const [fetchComunicados, { loading, error, data, fetchMore, refetch, networkStatus, called }] =
    useLazyQuery(COMUNICADOS_PAGINADO, {
      variables: {
        tamanhoPagina: TAMANHO_PAGINA_COMUNICADOS,
        comunicadoFilter: {
          ...filter
        }
      },
      notifyOnNetworkStatusChange: true
    });

  useEffect(() => {
    if (called) {
      refetch({
        comunicadoFilter: {
          ...filter
        }
      });
    }
  }, [called, filter]);

  const cleanData = () => {
    if (called) {
      setPage(0);
      setLastPageFetched(0);
      setTextoBusca('');
      setSections([...secoes]);
      setSituacoes([...situacoesSelecionadas]);
      setFilter({
        funcionarioUuid: authHelper.getUserUUID()
      });
    }
  };

  const nextPage = () => {
    if (data?.comunicados?.pageInfo?.hasNextPage && page === lastPageFetched) {
      fetchMore({
        variables: {
          cursor: data?.comunicados?.pageInfo?.endCursor || ''
        }
      });

      setLastPageFetched((p) => p + 1);
    }

    setPage((p) => p + 1);
  };

  const previousPage = () => {
    page > 0 && setPage((p) => p - 1);
  };

  const filterEmailByText = () => {
    setPage(0);
    setLastPageFetched(0);

    let filterAux = { ...filter };
    if (textoBusca?.length > 0) {
      filterAux.tituloIlike = `%${textoBusca}%`;
    } else {
      delete filterAux.tituloIlike;
    }

    setFilter(filterAux);
  };

  const filterEmailBySituacao = (idSituacao) => {
    const situacoesAux = situacoes.map((sit) => ({
      ...sit,
      selected: idSituacao === sit.id ? !sit.selected : sit.selected
    }));

    setPage(0);
    setLastPageFetched(0);

    setFilter((f) => ({
      ...f,
      statusIdIn: situacoesAux.filter((sit) => sit.selected).map((sit) => sit.id)
    }));

    setSituacoes(situacoesAux);
  };

  const filterEmailBySection = (idSecao) => {
    let s_aux = sections.map((s) => ({
      ...s,
      selected: s.id === idSecao
    }));

    setPage(0);
    setLastPageFetched(0);
    setTextoBusca('');

    setFilter({
      ...(idSecao === 0
        ? { funcionarioUuid: authHelper.getUserUUID() }
        : { setorUuid: authHelper.getClaimsUsuarioLogado().setor.uuid })
    });

    setSections(s_aux);
  };

  return (
    <MainContext.Provider
      value={{
        obterComunicados: fetchComunicados,

        page,
        lastPageFetched,
        loading,
        error,
        comunicados: data?.comunicados?.edges?.map((ed) => ed.node),
        pageInfo: data?.comunicados?.pageInfo,
        edgeCount: data?.comunicados?.edgeCount,
        totalCount: data?.comunicados?.totalCount,
        networkStatus,

        nextPage,
        previousPage,
        cleanData,

        filterEmailByText,
        filterEmailBySituacao,
        filterEmailBySection,

        executadoPrimeiraVez: called,
        textoBusca,
        setTextoBusca,
        situacoes,
        secoes: sections
      }}>
      {children}
    </MainContext.Provider>
  );
};

export const useMainContext = () => useContext(MainContext);
