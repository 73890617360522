import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';

const ItemMenu = (props) => {
  const [openCollapse, setOpenCollapse] = React.useState(false);

  return props.render === undefined || props.render === true ? (
    <>
      <ListItem
        button
        id={props.id}
        onClick={() => setOpenCollapse(!openCollapse)}
        style={props.style}>
        <ListItemIcon>{props.icon}</ListItemIcon>
        <ListItemText primary={props.titulo} />
        {openCollapse ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openCollapse} timeout="auto" unmountOnExit>
        {props.children}
      </Collapse>
    </>
  ) : (
    ''
  );
};

export default ItemMenu;
