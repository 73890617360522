import decode from 'jwt-decode';
import _ from 'lodash';

/**
 * Chaves no localStorage para os tokens.
 */
const ACCESS_TOKEN = 'user_token';
const REFRESH_TOKEN = 'refresh_token';

/**
 * Coloca o access token no localStorage.
 * @param {*} token
 */
const setAccessToken = (token) => {
  localStorage.setItem(ACCESS_TOKEN, token);
};

/**
 * Recupera o valor do access token do localStorage.
 */
const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN);
};

/**
 * Coloca o refresh token no localStorage.
 */
const setRefreshToken = (token) => {
  localStorage.setItem(REFRESH_TOKEN, token);
};

/**
 * Recupera o valor do refresh token do localStorage.
 */
const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN);
};

/**
 * Armazena os tokens (access e refresh) no localStorage.
 * @param {*} tokens
 */
const setUserTokens = (tokens) => {
  setAccessToken(tokens.access_token);
  setRefreshToken(tokens.refresh_token);
};

/**
 * Verifica se o token é 'fresh'. Utilizado em funcionalidades específicas,
 * como atualizações de dados.
 */
const isTokenFresh = () => {
  try {
    const token = getAccessToken();
    const decoded = decode(token);
    return decoded.fresh;
  } catch (err) {
    console.log('Erro ao tentar obter informação do token:', err);
    return false;
  }
};

/**
 * Verifica se o refresh token é válido. Quando não for, a renovação de tokens
 * de acesso não é mais permitido.
 */
const isRefreshTokenValid = () => {
  const token = getRefreshToken();
  return !!token && !_isTokenExpired(token);
};

/**
 * Verifica se o token de acesso ainda é válido.
 */
const isAccessTokenValid = () => {
  const token = getAccessToken();
  return token && !_isTokenExpired(token); // handwaiving here
};

/**
 * Verifica a validade de um token.
 * @param {*} token
 */
const _isTokenExpired = (token) => {
  try {
    const decoded = decode(token);
    if (decoded.exp < Date.now() / 1000) {
      return true;
    } else return false;
  } catch (err) {
    console.log('Erro ao tentar obter informação do token:', err);
    return false;
  }
};

/**
 * Limpa todos os dados armazenados no localStorage.
 */
const removeCredentials = () => {
  localStorage.clear();
};

/**
 * Recupera os dados armazenados no claims do usuário
 * logado.
 */
const getClaimsUsuarioLogado = () => {
  try {
    const token = getAccessToken();
    const decoded = decode(token);
    return decoded.user_claims;
  } catch (err) {
    console.log('Erro ao tentar obter informação do claims do token:', err);
    // Retornar um objeto de claims vazio, assim evita acesso a algum campo de um objeto undefined.
    return {
      uuid: '',
      nome: '',
      perfil: {
        id: '',
        descricao: ''
      },
      setor: {
        uuid: '',
        nome: '',
        funcionalidades: []
      }
    };
  }
};

/**
 * Recupera o identity do token de acesso.
 */
const getIdentity = () => {
  try {
    const token = getAccessToken();
    const decoded = decode(token);
    return decoded.identity;
  } catch (err) {
    console.log('Erro ao tentar obter a identificação do token:', err);
    return false;
  }
};

const getUserUUID = () => {
  try {
    const token = getAccessToken();
    const decoded = decode(token);
    return decoded.user_claims.uuid;
  } catch (err) {
    console.log('Erro ao tentar obter o uuid do token:', err);
    return false;
  }
};

const getTokenClaims = () => {
  try {
    const token = getAccessToken();
    const decoded = decode(token);
    return decoded.user_claims;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const usuarioPodeRevisar = () => {
  return getPermissoesSistema().comunicados.revisar;
};

const getPermissoesSistema = () => {
  const token = getAccessToken();
  let permissoes = getPermissoesToken(token);

  /**
   * Modifica o retorno de todas as permissões caso satisfaça as condições abaixo.
   */
  if (
    process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_RETAGUARDA_TODAS_AS_PERMISSOES &&
    process.env.REACT_APP_RETAGUARDA_TODAS_AS_PERMISSOES.toLocaleLowerCase() == 'true'
  ) {
    permissoes = JSON.parse(JSON.stringify(permissoes), (_, value) =>
      value === false ? true : value
    );
  }

  return permissoes;
};

// Funcao criada para poder ser memoizada,
// como a funcao getPermissoesSistema não recebe parametros
// caso o token fosse alterado a função continuaria retornando
// o valor antigo
function getPermissoesToken(token) {
  const decoded_token = decode(token);
  const acesso_funcionalidades =
    decoded_token.user_claims.setor.funcionalidades !== undefined
      ? decoded_token.user_claims.setor.funcionalidades.map((item) => item.chave)
      : [];

  // Criar mapa p['nome-permicao'] -> true ou false
  let p = Object.fromEntries(acesso_funcionalidades.map((key) => [key, true]));

  return {
    comunicados: {
      enviar_responder: Boolean(p['comunicados-enviar-responder']),
      revisar: Boolean(p['comunicados-revisar'])
    },
    alunos: {
      cadastrar: Boolean(p['alunos']),
      bloquear: Boolean(p['configuracoes-bloquear-acesso'])
    },
    turmas: {
      cadastrar: Boolean(p['turmas'])
    },
    disciplinas: {
      cadastrar: Boolean(p['disciplinas'])
    },
    responsaveis: {
      cadastrar: Boolean(p['responsaveis'])
    },
    cardapios: Boolean(p['cardapios']),
    eventos: {
      cadastrar: Boolean(p['eventos'])
    },
    rotinas: Boolean(p['rotinas']),
    funcionarios: {
      cadastrar: Boolean(p['funcionarios'])
    },
    galerias: Boolean(p['galerias']),
    setores: {
      cadastrar: Boolean(p['perfis'])
    },
    ausencias: Boolean(p['ausencias']),
    relatorios: Boolean(p['relatorios']),
    mensagens: {
      enviar_mensagens_setor: Boolean(p['enviar-mensagens-setor']),
      enviar_mensagens_privado: Boolean(p['enviar-mensagens-privado']),
      visualizar_mensagens_privado: Boolean(p['visualizar-mensagens-privado'])
    },
    informativos: {
      cadastrar: Boolean(p['informativos'])
    },
    configuracoes: Boolean(p['configuracoes-sistema'])
  };
}

getPermissoesToken = _.memoize(getPermissoesToken);

export const authHelper = {
  getPermissoesSistema,
  getTokenClaims,
  getClaimsUsuarioLogado,
  getRefreshToken,
  getAccessToken,
  getIdentity,
  getUserUUID,
  isRefreshTokenValid,
  isAccessTokenValid,
  isTokenFresh,
  removeCredentials,
  setUserTokens,
  usuarioPodeRevisar
};
