import { createAsyncAction } from "redux-promise-middleware-actions";
import { authHelper } from "../_helpers";
import { ausenciaService, funcionarioService } from "../_services";

const filtraAusencias = async (lista) => {
  let ausencias = [...lista];
  const isProfessor = authHelper.getClaimsUsuarioLogado().perfil.id === 2;
  if (isProfessor) {
    const disciplinasProfessorRequest =
      await funcionarioService.obterDisciplinasFuncionario(
        authHelper.getClaimsUsuarioLogado().uuid
      );
    const disciplinasProfessor = disciplinasProfessorRequest.data;
    let uuidsTurmas = [];
    if (!!disciplinasProfessor && disciplinasProfessor.length > 0) {
      disciplinasProfessor.forEach((dp) => {
        if (!!dp.turma && dp.turma.length > 0) {
          const uuids = dp.turma.map((turma) => turma.uuid);
          uuidsTurmas = [...uuidsTurmas, ...uuids];
        }
      });
      ausencias = ausencias.filter((ausencia) =>
        uuidsTurmas.includes(ausencia.turma.uuid)
      );
    }
  }
  return ausencias;
};

export const obterListaAusencias = createAsyncAction(
  "AUSENCIA_OBTER_LISTA",
  async () => {
    let ausencias = await ausenciaService.obterTodasAusencias();
    return await filtraAusencias(ausencias.data);
  }
);

export const obterListaAusenciasIntervalo = createAsyncAction(
  "AUSENCIA_OBTER_LISTA_INTERVALO",
  async (dataInicio, dataFim) => {
    let ausencias = await ausenciaService.obterListaAusenciasIntervalo(
      dataInicio,
      dataFim
    );
    return await filtraAusencias(ausencias.data);
  }
);

export const obterAusencia = createAsyncAction(
  "AUSENCIA_OBTER",
  async (uuid) => {
    return await ausenciaService.obterAusencia(uuid);
  }
);
