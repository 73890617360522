import { createAsyncAction } from 'redux-promise-middleware-actions';
import { alunoService } from '../_services';

export const salvarAluno = createAsyncAction('ALUNO_SALVAR', async (aluno) => {
  return await alunoService.salvarAluno(aluno);
});

export const obterListaAlunos = createAsyncAction('ALUNO_OBTER_LISTA', async () => {
  return await alunoService.obterListaAlunos();
});

export const removerAluno = createAsyncAction('ALUNO_REMOVER', async (uuid) => {
  return await alunoService.removerAluno(uuid);
});

export const obterAluno = createAsyncAction('ALUNO_OBTER', async (uuid) => {
  return await alunoService.obterAlunoPorUuid(uuid);
});

export const alterarAluno = createAsyncAction('ALUNO_ALTERAR', async (aluno) => {
  return await alunoService.alterarAluno(aluno);
});

export const obterListaAlunosComTurma = createAsyncAction(
  'ALUNO_TURMA_OBTER_LISTA',
  async (uuids) => {
    return await alunoService.obterListaAlunoComTurmas(uuids);
  }
);

export const obterListaAlunoComTurmasPorResponsavel = createAsyncAction(
  'ALUNO_TURMA_RESPONSAVEL_OBTER_LISTA',
  async (uuidResponsavel) => {
    return await alunoService.obterListaAlunoComTurmasPorResponsavel(uuidResponsavel);
  }
);

export const pesquisarAlunosParaDesabilitar = createAsyncAction(
  'ALUNO_PESQUISA_PARA_DESABILITAR',
  async (args) => {
    return await alunoService.pesquisarAlunosParaDesabilitar(args);
  }
);

export const desabilitarAlunos = createAsyncAction('ALUNO_DESABILITAR', async (uuids) => {
  return await alunoService.desabilitarAlunos(uuids);
});

export const habilitarAlunos = createAsyncAction('ALUNO_HABILITAR', async (uuids) => {
  return await alunoService.habilitarAlunos(uuids);
});

export const resetarAlunos = createAsyncAction('ALUNO_RESETAR', async () => {
  return [];
});
