import React, { useEffect } from "react";

import { Redirect, withRouter } from "react-router";

import { authHelper, firebaseHelper } from "../../_helpers";
import { authService } from "../../_services/auth.service";
import Rotas from "../../_constants/rotas";

import {
  makeStyles,
  useTheme,
  MuiThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { MainListItems } from "./ItemsMenu";
import ErrorBoundary from "./ErrorBoundary";
import SuspenseBoundary from "./SuspenseBoundary";
import BadgeNotificacoes from "./BadgeNotificacoes";

import menuLogo from "../../assets/imgs/ludica_logo_horizontalmdpi.png";
import defaultsDeep from "lodash/defaultsDeep";
import { baseTheme } from "../styles/Theme";
import { connect } from "react-redux";
import { obterParametros } from "../../_actions";

import { setorService, funcionarioService } from "../../_services";

import { setorHelper } from "../../_helpers";
import { signOut } from "firebase/auth";

const menuTheme = createTheme(
  defaultsDeep(
    {
      typography: {
        fontSize: "14px",
        fontFamily: "'Public Sans', sans-serif",
        fontWeight: 600,
        webkitFontSmoothing: "antialiased",
      },
      palette: {
        background: {
          paper: "white",
        },
        text: {
          primary: "rgb(60, 72, 88)",
        },
      },
      overrides: {
        MuiListItem: {
          root: {
            marginTop: "10px",
          },
          gutters: {
            padding: "10px 15px",
          },
        },
        MuiListItemIcon: {
          root: {
            fontSize: "20px",
            minWidth: "36px",
          },
        },
        MuiDrawer: {
          paperAnchorDockedLeft: {
            borderRight: "0",
            boxShadow:
              "0 10px 30px -12px rgb(0 0 0 / 42%), 0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)",
          },
        },
      },
    },
    baseTheme
  )
);

global._menuTheme = menuTheme;

const FIXED_AT_SCREEN_SIZE = "md";

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    height: "100vh",
    // position: 'fixed',
    whiteSpace: "nowrap",
    width: drawerWidth,
    // transition: theme.transitions.create('width', {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen
    // })
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  title: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  img: {
    height: 50,
    flex: 1,
    objectFit: "contain",
    paddingRight: theme.spacing(2),
  },
  menuList: {
    overflowY: "scroll",
    paddingLeft: "15px",
    paddingRight: "13px",
    marginRight: "2px",
    flex: 1,
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {},
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(116,121,129,0.25)",
      borderRadius: "8px",
      xoutline: "1px solid slategrey",
    },
  },
  divider: {
    margin: "0 15px",
  },
}));

function ResponsiveDrawer(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();

  const container =
    window !== undefined ? () => window().document.body : undefined;

  if (props.isSmallScreen) {
    return (
      <MuiThemeProvider theme={menuTheme}>
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={props.open}
          onClose={props.onClose}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {props.children}
        </Drawer>
      </MuiThemeProvider>
    );
  }

  return (
    <>
      <MuiThemeProvider theme={menuTheme}>
        <Drawer
          classes={{ paper: classes.drawerPaper }}
          variant="permanent"
          open
        >
          {props.children}
        </Drawer>
      </MuiThemeProvider>
    </>
  );
}

const Menu = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = !useMediaQuery(
    theme.breakpoints.up(FIXED_AT_SCREEN_SIZE)
  );
  const [openOnSmallScreens, setOpenOnSmallScreens] = React.useState(false);

  const [profilePhoto, setProfilePhoto] = React.useState("");

  const usuario = authHelper.getTokenClaims();

  useEffect(() => {
    setOpenOnSmallScreens(false);
    props.obterParametros();
    funcionarioService
      .obter(usuario.uuid)
      .then((resp) => (resp ? resp.data : undefined))
      .then((data) => {
        if (data.foto) {
          setProfilePhoto(data.foto);
        } else {
          setorService
            .obter(usuario.setor.uuid)
            .then((resp) => (resp ? resp.data : undefined))
            .then((data) => {
              if (data) {
                let img = setorHelper.icones.filter(
                  (icone) => icone.value === data.icone
                )[0].label;
                setProfilePhoto(img.props.src);
              }
            })
            .catch((err) => console.log(err.response));
        }
      })
      .catch((err) => console.log(err.response));
  }, [isSmallScreen]);

  const toggleDrawer = () => {
    setOpenOnSmallScreens(!openOnSmallScreens);
  };

  let titulo_menu = (
    <Typography
      component="h1"
      variant="h6"
      color="inherit"
      noWrap
      className={classes.title}
    >
      Lúdica Soft
    </Typography>
  );

  function renderDrawer() {
    return (
      <ResponsiveDrawer
        open={openOnSmallScreens}
        onClose={toggleDrawer}
        isSmallScreen={isSmallScreen}
      >
        <div className={classes.toolbarIcon}>
          <img className={classes.img} alt="logo" src={menuLogo} />
          {isSmallScreen && (
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          )}
        </div>
        <Divider className={classes.divider} />
        <List className={classes.menuList}>
          <MainListItems />
        </List>
        <Divider className={classes.divider} />
        <span>Versão: {process.env.REACT_APP_VERSAO_WEB_APP}</span>
        <span>Build: {process.env.REACT_APP_DATA_BUILD}</span>
      </ResponsiveDrawer>
    );
  }

  const logout = () => {
    return signOut(firebaseHelper.auth).then(() => {
      authService.logout().finally(() => {
        authHelper.removeCredentials();
        props.history.push(Rotas.autenticacao.login);
      });
    });
  };

  function renderAppBar() {
    if (usuario) {
      const usuario_setor =
        usuario === undefined
          ? ""
          : usuario.setor === undefined
          ? ""
          : usuario.setor.nome;
      titulo_menu = (
        <>
          <div>
            <img
              height="48"
              style={{ borderRadius: "50%" }}
              src={profilePhoto}
              alt="profilePhoto"
            />
          </div>
          <div
            style={{
              diplay: "flex",
              direction: "row",
              width: "100%",
              paddingLeft: "10px",
            }}
          >
            <Typography
              name="tituloUsuario"
              component="h1"
              variant="subtitle1"
              color="inherit"
              style={{ fontWeight: "700" }}
            >
              {usuario.nome}
            </Typography>
            <Typography
              name="setorUsuario"
              component="h1"
              variant="body2"
              color="inherit"
            >
              {usuario_setor}
            </Typography>
          </div>
        </>
      );
    }

    return (
      <AppBar position="relative" elevation={0}>
        <Toolbar className={classes.toolbar} style={{ paddingLeft: "10px" }}>
          {isSmallScreen && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="Open drawer"
              onClick={toggleDrawer}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          )}
          {titulo_menu}
          <Typography
            name="tituloUsuario"
            component="h1"
            variant="body2"
            color="inherit"
            style={{ width: "10%" }}
          >
            Ano letivo {props.dicionarioParametros["ano_letivo"]}
          </Typography>
          <BadgeNotificacoes />
          <IconButton name="btnLogout" onClick={() => logout()} color="inherit">
            <ExitToAppIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    );
  }

  function renderContent() {
    // Redireciona para a página de login se o token não for mais válido.
    if (!authHelper.isAccessTokenValid()) {
      return <Redirect to={Rotas.autenticacao.login} />;
    }
    // Caso ainda seja válido renderizar a página normalmente.
    return (
      <SuspenseBoundary>
        <ErrorBoundary>
          <main style={{ flexGrow: 1 }}>{props.children}</main>
        </ErrorBoundary>
      </SuspenseBoundary>
    );
  }

  return (
    <div style={{ display: "flex", minHeight: "100vh" }}>
      <div style={{ flexShrink: 0, width: isSmallScreen ? 0 : drawerWidth }}>
        {renderDrawer()}
      </div>
      <div style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
        {renderAppBar()}
        {renderContent()}
      </div>
    </div>
  );
};

function mapStateToProps(store) {
  const { dicionarioParametros } = store.ParametrosReducer;

  return {
    dicionarioParametros: dicionarioParametros || {},
  };
}

const mapDispatchToProps = {
  obterParametros,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Menu));
