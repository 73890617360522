import { createAsyncAction } from 'redux-promise-middleware-actions';
import { rotinaService } from '../_services/rotina.service';

export const gravarRotinaAluno = createAsyncAction('ROTINA_ALUNO_SALVAR', async (rotina) => {
  return await rotinaService.gravarRotinaAluno(rotina);
});

export const obterTodasRotinasAlunos = createAsyncAction('ROTINA_ALUNO_OBTER_LISTA', async () => {
  return await rotinaService.obterTodasRotinasAlunos();
});

export const obterRotinaAlunoPorUuid = createAsyncAction(
  'ROTINA_ALUNO_OBTER',
  async (uuid, data) => {
    return await rotinaService.obterRotinaAlunoPorUuid(uuid, data);
  }
);

export const alterarRotinaAluno = createAsyncAction('ROTINA_ALUNO_ALTERAR', async (id, rotina) => {
  return await rotinaService.alterarRotinaAluno(id, rotina);
});

export const gravarRotinaTurma = createAsyncAction('ROTINA_TURMA_SALVAR', async (rotina) => {
  return await rotinaService.gravarRotinaTurma(rotina);
});

export const obterTodasRotinasTurmas = createAsyncAction('ROTINA_TURMA_OBTER_LISTA', async () => {
  return await rotinaService.obterTodasRotinasTurmas();
});

export const obterRotinaTurmaPorUuid = createAsyncAction(
  'ROTINA_TURMA_OBTER',
  async (uuid, data) => {
    return await rotinaService.obterRotinaTurmaPorUuid(uuid, data);
  }
);

export const alterarRotinaTurma = createAsyncAction('ROTINA_TURMA_ALTERAR', async (id, rotina) => {
  return await rotinaService.alterarRotinaTurma(id, rotina);
});

export const obterRotinasTurmasPaginada = createAsyncAction(
  'ROTINAS_TURMA_PAGINADO',
  async (pagina, tamanhoPagina, ano) => {
    return await rotinaService.obterRotinasTurmasPaginada(pagina, tamanhoPagina, ano);
  }
);

export const obterRotinasAlunosPorUuidData = createAsyncAction(
  'ROTINAS_ALUNOS_TURMA_DATA',
  async (uuid_turma, data_rotina) => {
    return await rotinaService.obterRotinasAlunosPorUuidData(uuid_turma, data_rotina);
  }
);
